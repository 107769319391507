import React, { useContext, useEffect } from 'react';
import AuthContextProvider from '../../../contexts/AuthContext';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import history from '../../../history';
import { Link } from 'react-router-dom';

const ProviderRequestConfirmed = () => {

    const { npi: npiFromContext, npiThatNeedsAuthorization, npiNameThatNeedsAuthorization } = useContext(ProvidersContext);

    useEffect(() => {
        console.log(npiFromContext);
        if (npiFromContext === '') {
            history.push('/selectProviderType');
        }
    }, [npiFromContext]);

    return (
        <AuthContextProvider>
            <div>
                <div className="row indented gap-double row--centered">
                    <div className="col"><h2>Request Sent</h2></div>
                    <div className="col-grow-3">
                        <ul className="wizards-steps ml-3">
                            <li><span className="sr-only ">Step 1</span></li>
                            <li><span className="sr-only ">Step 2</span></li>
                            <li><span className="sr-only ">Step 3</span></li>
                            <li className="current"><span className="sr-only ">Step 4</span></li>
                        </ul>
                    </div>
                </div>

                {/* <!-- Contenido --> */}

                <div className="container-with-sidebar__content-block">
                    <div className="row indented gap-triple">
                        <div className="col instructions">

                            <p>
                                <img src="images/image-request-sent.png" alt="Search Provider" className="mb-2 mt-2" /></p>
                        </div>
                        <div className="col-grow-3">
                            <p className="text-bold mb-2 p-primary">Your request to access</p>
                            <p className="mt-2 p-primary">
                                NPI  {npiThatNeedsAuthorization} <br />
                                {npiNameThatNeedsAuthorization}
                            </p>
                            <p className="text-bold mb-2 p-primary">Has been sent</p>
                            <p className="text-bold mb-2 p-primary">Please wait until your request is approved by the Primary Representative.</p>
                            <div className="mt-3"><Link to="/providersList" className="btn btn-primary">Continue</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthContextProvider>
    );
}

export default ProviderRequestConfirmed;