import React, { useEffect, useState, useContext } from 'react';
import Input from '../../reusable/Input';
import graphQL from '../../../apis/graphQL';
import { ForgotPasswordContext } from '../../../contexts/ForgotPasswordContext';
import history from '../../../history';

let positionsArray = [];

const QuestionsPasswordRecovery = () => {

    const { email, setToken } = useContext(ForgotPasswordContext);
    // questions
    const [questions, setQuestions] = useState([]);
    // formState
    const [formState, setFormState] = useState({ values: {} });

    useEffect(() => {
        if (email === '') {
            history.push('/emailPasswordRecovery');
            return;
        }
        (async () => {
            //setLoading(true);
            const response = await graphQL(`query{
                getUsersQuestions(email: "${email}"){
                    _id
                    description
                }
                }`);
            // setLoading(false);
            if (response.getUsersQuestions) {
                do {
                    //random number between 0 to 5 because the questions will be an array of 5 questions
                    let random = Math.floor(Math.random() * 5);
                    if (!positionsArray.includes(random)) {
                        positionsArray.push(random);
                    }
                } while (positionsArray.length < 3);
                setQuestions(response.getUsersQuestions);

            } else if (response) {
                //setLoading(false);
                //setGraphQLErrors(response);
            }
        })()
    }, [email])

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;
        const checked = e.target.checked
        //Change state
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    }

    const validateClicked = async () => {
        //setLoading(true);
        const response = await graphQL(`query{
            verifyAnswersForQuestions(email:"${email}", question1Id: "${questions[positionsArray[0]]._id}", question2Id: "${questions[positionsArray[1]]._id}", question3Id: "${questions[positionsArray[2]]._id}", answer1: "${formState.values.question1}", answer2: "${formState.values.question2}", answer3: "${formState.values.question3}"){
                success
                message
            }
            }`);
        //setLoading(false);
        if (response.verifyAnswersForQuestions) {
            setToken(response.verifyAnswersForQuestions.message);
            history.push('/newPasswordRecovery');
        } else if (response) {

            // setLoading(false);
            // setGraphQLErrors(response);
        }
    }

    return (
        <div>
            <div className="login-wrapper mt-3">
                <div className="container">
                    {/* <!-- Two Factor 		--> */}
                    <div className="panel-clear">
                        <div className="row gap-triple">
                            <div className="col">
                                <h2>Password Recovery</h2>
                                <p className="text-center">
                                    <img
                                        src="/images/image-password-recovery.png"
                                        alt="Illustration depicting a lock and password"
                                        className="mt-1"
                                    />
                                </p>
                            </div>
                            <div className="col-grow-2">
                                <p className="p-primary text-bold">
                                    To reset your password you will need to successfully answer your
                                    security questions:
                                </p>
                                <div className="mt-3">
                                    {/* question1 field*/}
                                    <Input
                                        type="text"
                                        label={questions.length > 0 ? questions[positionsArray[0]].description : ''}
                                        id="question1"
                                        onChange={onChange}
                                        value={formState.values.question1 || ''}
                                        placeholder=""
                                        stacked
                                    />
                                    {/* .question1 field */}
                                    {/* question2 field*/}
                                    <Input
                                        type="text"
                                        label={questions.length > 0 ? questions[positionsArray[1]].description : ''}
                                        id="question2"
                                        onChange={onChange}
                                        placeholder=""
                                        value={formState.values.question2 || ''}
                                        stacked
                                    />
                                    {/* .question2 field */}
                                    {/* question3 field*/}
                                    <Input
                                        type="text"
                                        label={questions.length > 0 ? questions[positionsArray[2]].description : ''}
                                        id="question3"
                                        onChange={onChange}
                                        value={formState.values.question3 || ''}
                                        placeholder=""
                                        stacked
                                    />
                                    {/* .question3 field */}
                                    <div className="form-group mt-3">
                                        <a
                                            to="/forgot-password/method-password-recovery"
                                            className="btn btn-secondary mr-2"
                                        >Cancel</a>
                                        <button type="button"
                                            className="btn btn-primary"
                                            onClick={validateClicked}
                                        >Validate</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default QuestionsPasswordRecovery;