import React, { useContext, useState, useEffect } from 'react';
import { RegistrationContext } from '../../../contexts/RegistrationContext';
import { Link } from 'react-router-dom';
import graphQL from '../../../apis/graphQL';
import history from '../../../history';

const Step3 = () => {

    //email from context
    const { email } = useContext(RegistrationContext);

    useEffect(() => {
        if (email === '') {
            history.push('/');
        }
    }, [email])

    // loading
    const [loading, setLoading] = useState(false);

    const resendActivationEmail = async () => {
        setLoading(true);
        const response = await graphQL(`mutation{
            resendRegistrationEmail(email: "${email}"){
                success
                message
            }
        }`);
        setLoading(false);
        if (response.resendRegistrationEmail) {

        } else if (response) {
            //setGraphQLErrors(response);
        }
    }

    return (
        <div>
            <div>
                <div className="container">
                    <div className="row indented gap-double">
                        <div className="col instructions">
                            <h2>Email Verification</h2>
                            <p>
                                <img src="/images/ilus-email-sent.png" alt="Email Illustration" className="mb-2 mt-2" />
                            </p>
                        </div>
                        <div className="col-grow-3 instructions">
                            <p className="mt-2">
                                To make sure that the mail<strong> {email} </strong> belongs to you, we have sent you an email.
                            </p>
                            <p>Please check your email and follow the link that we have sent you.</p>
                            <div className="mt-3">
                                <Link to="/main" className="btn btn-secondary mr-3">Back to log in</Link>
                                {loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> :
                                    <button className="btn btn-link-inline mt-1" onClick={resendActivationEmail}>
                                        Resend Verification
                                </button>
                                }
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Step3;