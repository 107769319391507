import React, { useEffect, useContext } from 'react';
import TermsAndConditions from '../TermsAndConditions';
import { Link } from 'react-router-dom';
import { TermsAndAgreementsContext } from '../../contexts/TermsAndAgreementsContext';

const FirstTimeLoginTermsAndConditions = () => {

  const { setProtectMain } = useContext(TermsAndAgreementsContext);

  useEffect(() => {
    setProtectMain(false);

    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
          document.getElementById("action-buttons").classList.remove("floating-block");
        } else {
          document.getElementById("action-buttons").classList.add("floating-block");
        }
      });
      observer.observe(document.querySelector("#top-of-site-pixel-anchor"));
    }
  }, []);

  return (
    <div>
      <div>
        <div className="login-wrapper mt-1">
          <div className="container">
            <div className="panel-clear text-terms">
              <TermsAndConditions />
              <div id="action-buttons" className="mt-3 floating-block">
                <p className="text-center">
                  <a href="https://www.asespr.org/i/proveedores-2/" className="btn btn-secondary mr-2">Cancel</a>
                  <Link to="/main" className="btn btn-primary">I Agree</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="top-of-site-pixel-anchor"></div>
      </div >
    </div>

  );
}

export default FirstTimeLoginTermsAndConditions;