import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import graphQL from '../../../apis/graphQL';
import { Link } from 'react-router-dom';
import history from '../../../history';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import AuthContextProvider from '../../../contexts/AuthContext';

const SearchPMG = () => {

    const { setNpi, setEnumerationType, setFirstName, setLastName, setNpiThatNeedsAuthorization, setNpiNameThatNeedsAuthorization, setNpiPrimaryRepresentative } = useContext(ProvidersContext);
    // disableInput
    const [disableInput, setDisableInput] = useState(false);
    // npi
    const [npi, setNpiLocal] = useState('');
    // loading
    const [loading, setLoading] = useState(false);
    // providerFound
    const [providerFound, setProviderFound] = useState(false);
    // notProviderFound
    const [providerNotFound, setProviderNotFound] = useState(false);

    const npiChangeInput = async (e) => {
        const value = e.target.value;
        setNpiLocal(value);
        if (value.length === 10) {
            setDisableInput(true);
            setLoading(true);
            console.log(value);
            const response = await graphQL(`query{
                findNPIInCMSDatabase(npi: "${value}",type: ""){
                    success
                    results{
                        enumerationType
                        firstName
                        lastName
                        error
                    }
                }
                }`);
            setLoading(false);
            setDisableInput(false);
            if (response.findNPIInCMSDatabase.success) {
                const res = response.findNPIInCMSDatabase.results;
                setEnumerationType(res.enumerationType);
                setFirstName(res.firstName);
                setLastName(res.lastName);
                setNpiNameThatNeedsAuthorization(`${res.firstName} ${res.lastName}`);
                setProviderFound(true);
                setProviderNotFound(false);

            } else {
                console.log('not found');
                setLoading(false);
                setProviderFound(false);
                setProviderNotFound(true);
                setDisableInput(false);
                //setGraphQLErrors(response);
            }
        } else {
            setLoading(false);
            setProviderFound(false);
            setProviderNotFound(false);
        }
    }

    const continueButtonClicked = async () => {
        history.push('/permissionVerificationPMG');
        return;

        console.log(npi);
        setNpi(npi);
        //Verify if Already associated
        setLoading(true);
        const resp = await graphQL(`query{
                    verifyIfNPIIsAlreadyAssociated(npi: ${parseInt(npi)}){
                        success
                        message
                    }
                    }`);
        setLoading(false);
        if (resp.verifyIfNPIIsAlreadyAssociated) {
            history.push('/permissionVerificationPMG');
        } else if (resp) {
            setLoading(false);
            setNpiPrimaryRepresentative(resp.name);
            setNpiThatNeedsAuthorization(npi);
            history.push('/requestAccessToProvider');
            //  setGraphQLErrors(response);
        }
    }

    return (
        <AuthContextProvider>
            <div>
                {/* <!-- Barra de Avance --> */}

                <div className="row indented gap-double row--centered">
                    <div className="col"><h2>Search PMG</h2></div>
                    <div className="col-grow-3">
                        <ul className="wizards-steps ml-3">
                            <li><span className="sr-only ">Step 1</span></li>
                            <li className="current"><span className="sr-only ">Step 2</span></li>
                            <li><span className="sr-only ">Step 3</span></li>
                            <li><span className="sr-only ">Step 4</span></li>
                            <li><span className="sr-only ">Step 5</span></li>
                        </ul>
                    </div>
                </div>
                <div className="container-with-sidebar__content-block">
                    <div className="row indented gap-double">
                        <div className="col instructions">
                            <p>
                                <img src="images/image-search-provider.png" alt="Search Provider" className="mb-2 mt-2" /></p>
                        </div>
                        <div className="col-grow-2">
                            <p className="text-bold mb-2 p-primary">PMG Info:</p>
                            <div className="form-group">
                                <label htmlFor="npi">Federal Tax Id:</label>
                                <input type="text" placeholder="Ex. 1234567890" onChange={npiChangeInput} value={npi}
                                    maxLength={10} disabled={disableInput}
                                />
                                <span>
                                    {providerFound ?
                                        <span className="ml-1 message-info__input">
                                            <FontAwesomeIcon icon={faCheckCircle} size="lg" className="color-green" />
                                            &nbsp; Provider
                                            Found
                                        </span>
                                        : <span></span>}
                                    {providerNotFound ?
                                        <span className="ml-1 message-info__input">
                                            <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="color-red" />
                                            &nbsp;Provider not Found
                                    </span> : <span></span>}
                                </span>

                            </div>
                            {providerNotFound ?
                                <div className="npi-not-found-message">
                                    <ul>
                                        <li>Verify that the number is written correctly.</li>
                                        <li>
                                            If you have recently registered with CMS, you must wait until XX hours has passed before
                                            it is reflected on the systems. Wait a while and try again.
                                        </li>
                                    </ul>
                                </div>
                                : <div></div>}

                            <div className="form-group mt-3">
                                <Link to="/selectProviderType" className="btn btn-secondary mr-2"
                                >Cancel</Link>
                                <button
                                    // disabled={!providerFound}
                                    type="button"
                                    onClick={continueButtonClicked}
                                    className="btn btn-primary"
                                >{!loading ? 'Continue' : <div className="loader-linear--bg-white"><span></span><span></span><span></span></div>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthContextProvider>
    );
}

export default SearchPMG;