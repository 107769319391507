import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

const FaqRegister = (props) => {
    return (
        <div>
            <div className="horizontal-bar-wrapper">
                <div className="container">
                    <div className="horizontal-bar">
                        <p className="goBack-faq">
                            <Link to="/main">
                                <FontAwesomeIcon icon={faArrowCircleLeft}/> Go Back
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <div className="row">
                    <div className="col">
                        <h2>How can we help you?</h2>
                        <nav className="menu-help mt-2">
                            <ul>
                                <li className="current">
                                    <Link to="/main">Register an account</Link>
                                </li>
                                <li >
                                    <Link to="/main">Sign in process</Link>
                                </li>
                                <li >
                                    <Link to="/main">Add a provider</Link>
                                </li>
                            </ul>
                        </nav>
                        <p className="text-center mt-3">
                            <img src="/images/ilus-faqs.png" alt="A question mark drawing" />
                        </p>
                    </div>
                    <div className="col-grow-3 question-list">
                        <div className="question-group">
                            <h3>I haven’t received a verification email.</h3>
                            <p>If you registered in our site and did not received a verification email, you can do the following:</p>
                            <ul>
                                <li>Check your spam or junk folder.</li>
                                <li>
                                    You can <Link to="/resendEmailRequest">resend a confirmation email</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="question-group">
                            <h3>Which email should I use to register?</h3>
                            <p>You can use your personal account to register. Your account and the providers profile are managed separately.</p>
                        </div>
                        <div className="question-group">
                            <h3>Which profile should I select?</h3>
                            <p>On the registration process you will be asked to choose a profile. Here is some more information about them:</p>
                            <ul>
                                <li>
                                    <strong>I’m a provider:</strong>you are a registered provider and want to view your billing information.
                                </li>
                                <li>
                                    <strong>I represent a provider:</strong> you are not a provider but you are authorized by them to view their information. You can view information of multiple providers.
                                </li>
                                <li>
                                    <strong>Both:</strong>you are both a registered provider and also are authorized to manage information of other providers.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqRegister;