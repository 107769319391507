import React, { useContext, useState } from 'react';
import Input from '../../reusable/Input';
import AuthContextProvider from '../../../contexts/AuthContext';
import { LoggedUserContext } from '../../../contexts/LoggedUser';
import graphQL from '../../../apis/graphQL';
import { Link } from 'react-router-dom';
import history from '../../../history';

const VerificationCode = (props) => {

    const { email, connectedProviders } = useContext(LoggedUserContext);
    // loading
    const [loading, setLoading] = useState(false);
    // token
    const [token, setToken] = useState('');
    // disableOk
    const [disableOk, setDisableOk] = useState(true);

    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState(false);

    const validateVerificationCode = async (e) => {
        e.preventDefault();
        setLoading(true);
        const response = await graphQL(`query{
            verifyToken(token: "${token}"){
                success
                message
            }
        }`);
        setLoading(false);
        if (response.verifyToken) {
            if (connectedProviders.length === 0) {
                history.push('/emptyList');
            } else {
                history.push('/billing');
            }
        } else if (response) {
            setLoading(false);
            setGraphQLErrors(response);
        }
    }

    const onChange = (e) => {
        setGraphQLErrors(false);
        const value = e.target.value;
        setToken(value);
        if (value.length === 6) {
            setDisableOk(false);
        } else {
            setDisableOk(true);
        }
    }

    return (
        <AuthContextProvider>
            <div className="login-wrapper mt-3">
                <div className="container">
                    {/* <!-- Two Factor --> */}
                    <div className="panel-clear">
                        <div className="row">
                            <div className="col">
                                <h2>2 Factor Authentication</h2>
                                <p className="text-center">
                                    <img src="/images/ilus-seguridad.png" alt="Security Note" className="mt-1" />
                                </p>
                            </div>
                            <div className="col-grow-2">
                                <p className="p-primary">
                                    A message with a verification code has been sent to your email:
                                <span className="text-bold"> {email}</span>
                                </p>

                                <p className="text-bold p-primary">Enter the code to continue:</p>

                                <form action={validateVerificationCode}>
                                    {/* verificationCode field*/}
                                    <Input
                                        type="text"
                                        label="Verification Code:"
                                        id="verificationCode"
                                        placeholder=""
                                        onChange={onChange}
                                        maxLength={6}
                                    />
                                    {/* .verificationCode field */}
                                    {/* Error Message */}
                                    {graphQLErrors ?
                                        <span
                                            style={{ color: 'red' }}>{graphQLErrors}
                                        </span> : <span></span>
                                    }
                                    {/* .Error Message */}

                                    <div className="form-group text-center">
                                        <Link to="/main" className="btn btn-secondary mr-2"
                                        // @click="logout()"
                                        >Cancel</Link>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={validateVerificationCode}
                                            disabled={disableOk}
                                        >{loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> : 'Ok'}</button>

                                    </div>
                                </form>

                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </AuthContextProvider>
    );
}

export default VerificationCode;