import ases from './ases';

export default async (query) => {
    try {
        let response = await ases.post('/graphql', {
            query: query
        });
        if (response.data.errors) {
            return response.data.errors[0].data;
        } else if (response.data.data) {
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}
