import React from 'react';
import graphQL from '../../apis/graphQL';

const ProviderAccessRequestedRow = (props) => {
    const { firstName, lastName, npi } = props;

    const cancelRequest = async () => {
        const response = await graphQL(`mutation{
        cancelRequest(npi: ${npi}){
            success
            message
        }
        }`);
        
        if (response.queryName) {

        } else if (response) {
        }
    }

    return (
        <tr className="clickable-row" key={npi}>
            <td>
                <strong>{`${firstName} ${lastName}`}</strong>
                <br />
                <span className="npi">NPI: {npi || 'npi'}</span>
            </td>
            <td>Authorized Representative</td>
            <td>Access Requested</td>
            <td>
                <a class="btn btn-secondary" onClick={cancelRequest}>Cancel request</a>
            </td>

            <td className="text-center">

            </td>
        </tr>
    );
}

export default ProviderAccessRequestedRow;