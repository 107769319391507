import React, { useContext, useState, useEffect } from 'react';
import Input from '../../reusable/Input';
import { ForgotPasswordContext } from '../../../contexts/ForgotPasswordContext';
import { Link } from 'react-router-dom';
import history from '../../../history';
import graphQL from '../../../apis/graphQL';


const NewPasswordRecovery = () => {

    const passwordRegexValidation = () => {
        let errorMessage = [];
        //Contains a number validation
        var regex = /[0-9]/g;
        if (regex.exec(password) == null) {
            errorMessage.push("Password must contain a number");
        }
        //length validation needs 8 characters mininum
        if (password.length < 8) {
            errorMessage.push("Password must at least 8 characters long");
        }
        //Does not contain email username
        if (email != null && email.includes("@")) {
            let username = email.match(/^([^@]*)@/)[1];
            if (password.includes(username)) {
                errorMessage.push("Password cannot contains your username");
            }
        }
        //Contains at least one special character
        regex = /^[a-zA-Z0-9 ]*$/;
        if (regex.exec(password) != null) {
            errorMessage.push("Password must contain at least a special character");
        }
        if (errorMessage.length === 0) {
            setPasswordError(false);
        } else {
            setPasswordError(errorMessage.map((error, index) => {
                return <p key={index}>{error}</p>
            }));
        }
    }

    const { email, token } = useContext(ForgotPasswordContext);
    // password
    const [password, setPassword] = useState('');
    // confirmationPassword
    const [confirmationPassword, setConfirmationPassword] = useState('');
    // loading
    const [loading, setLoading] = useState(false);
    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState(false);
    // passwordError
    const [passwordError, setPasswordError] = useState(false);

    //Every time that password field changes, run the regex verification; if password isn't empty
    useEffect(() => {
        if (password !== '') {
            passwordRegexValidation();
        } else {
            setPasswordError(false);
        }
    }, [password]);

    useEffect(() => {
        if (email === '') {
            history.push('/emailPasswordRecovery');
        }
    })

    const coninueButtonClicked = async () => {
        if (password !== confirmationPassword) {
            setGraphQLErrors('Passwords don\'t match');
            return;
        } else if (passwordError !== false) {
            setGraphQLErrors('Password requirements needs to be completed');
            return;
        }

        setLoading(true);
        const response = await graphQL(`mutation{
            changePassword(email: "${email}", token: "${token}", password: "${password}"){
                success
                message
            }
            }`);
        setLoading(false);
        if (response.changePassword) {
            history.push('/successPasswordRecovery');
        } else if (response) {
            setLoading(false);
        }
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }
    const onChangeConfirmationPassword = (e) => {
        setConfirmationPassword(e.target.value);
    }

    return (
        <div>
            <div className="login-wrapper mt-3">
                <div className="container">
                    {/* <!-- Two Factor 		--> */}
                    <div className="panel-clear">
                        <div className="row gap-triple">
                            <div className="col">
                                <h2>Password Recovery</h2>
                                <p className="text-center">
                                    <img
                                        src="/images/image-password-recovery.png"
                                        alt="Illustration depicting a lock and password"
                                        className="mt-1"
                                    />
                                </p>
                            </div>
                            <div className="col-grow-2">
                                <p className="p-primary text-bold">
                                    Please enter a new password and the new password again for
                                    verification:
                                </p>
                                <div className="mt-3">
                                    <Input
                                        type="password"
                                        label="Password:"
                                        id="password"
                                        placeholder=""
                                        onChange={onChangePassword}
                                        errorMessage={passwordError}
                                        disabled={false}
                                        optional={false} />
                                    {/* confirmPassword field*/}
                                    <Input
                                        type="password"
                                        label="Confirm Password:"
                                        id="confirmPassword"
                                        onChange={onChangeConfirmationPassword}
                                        errorMessage={graphQLErrors}
                                        placeholder=""
                                    />
                                    {/* .confirmPassword field */}


                                    <div className="form-group mt-3">
                                        <Link to="/main" className="btn btn-secondary mr-2">Cancel</Link>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={coninueButtonClicked}
                                        >Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default NewPasswordRecovery;