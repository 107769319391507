import React, { useContext } from 'react';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import AuthContextProvider from '../../../contexts/AuthContext';

const Connected = () => {

    const { npi, firstName, lastName } = useContext(ProvidersContext);

    return (
        <div>
            <div className="container-with-sidebar__heading-bar sticky"><div className="row row--centered"> <div className="col-grow-2"><h2>Provider Connected</h2></div>
                <div className="col-grow-3">
                    <ul className="wizards-steps ml-3">
                        <li><span className="sr-only ">Step 1</span></li>
                        <li><span className="sr-only ">Step 2</span></li>
                        <li><span className="sr-only ">Step 3</span></li>
                        <li><span className="sr-only ">Step 4</span></li>
                        <li className="current"><span className="sr-only ">Step 5</span></li>
                    </ul>
                </div>
            </div>
            </div>

            {/* <!-- Contenido --> */}
            <div className="container-with-sidebar__content-block">
                <div className="row indented gap-triple">
                    <div className="col instructions">
                        <p>
                            <img src="images/image-connected.png" alt="Search Provider" className="mb-2 mt-2" /></p>
                    </div>
                    <div className="col-grow-3">
                        <p
                            className="text-bold mb-2 p-primary"
                        ></p>
                        <p className="p-primary">Is now connected as a Primary Representative to:</p>
                        <p className="text-bold mt-2 p-primary">
                            NPI {npi}
                            <br />
                            {firstName} {lastName}
                        </p>
                        <div className="mt-3">
                            <a href="/providersList" className="btn btn-primary"
                            // @click="continueToNextStep()"
                            >Continue</a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Connected;