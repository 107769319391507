import React from 'react';
import { Link } from 'react-router-dom';
import AuthContextProvider from '../../../contexts/AuthContext';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectProviderType = () => {
    return (
        <AuthContextProvider>
            <div>

                <div className="container-with-sidebar__heading-bar sticky"><div className="row row--centered"><div className="col-grow-2"><h2><Link to="/providersList"><FontAwesomeIcon className="icono-cancel" icon={faChevronLeft} /></Link>Select type of connection</h2></div><div className="col"><ul className="wizards-steps ml-3">
                    <li className="current"><span className="sr-only ">Step 1</span></li>
                    <li><span className="sr-only ">Step 2</span></li>
                    <li><span className="sr-only ">Step 3</span></li>
                    <li><span className="sr-only ">Step 4</span></li>
                    <li><span className="sr-only ">Step 4</span></li>
                </ul>
                </div>
                </div>
                </div>
                <section class="container-with-sidebar__content-block">
                    <div className="row ml-2 mr-2 providers-select">
                        <div className="col mb-2">
                            <Link to={{
                                pathname: '/searchProvider',
                                state: {
                                    type: 'Individual'
                                }
                            }}>
                                <div className="panel text-center">
                                    <img src="images/ilustration-individual-provider.png" />
                                    <h3>Individual Provider</h3>
                                </div>
                            </Link>
                        </div>
                        <div className="col mb-2">
                            <Link to={{
                                pathname: '/searchProvider',
                                state: {
                                    type: 'Organizational'
                                }
                            }}>
                                <div className="panel text-center">
                                    <img src="images/ilustration-organizational-provider.png" />
                                    <h3>Organizational Provider</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col mb-2">
                            <Link to="/searchProviderPMG">
                                <div className="panel text-center">
                                    <img src="images/ilustration-pmg.png" />
                                    <h3>Primary Medical Group (PMG)</h3>
                                </div>
                            </Link>
                        </div>

                    </div>
                </section>
            </div >
        </AuthContextProvider>
    )


}

export default SelectProviderType;                                        