import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserMd } from '@fortawesome/free-solid-svg-icons'
import {LoggedUserContext} from '../../contexts/LoggedUser';
import history from '../../history';
import { Link } from 'react-router-dom';

const UserDropDown = () => {
    // activeDropDown
    const [activeDropDown, setActiveDropDown] = useState('dropdown-toggle-off');

    const {firstName, lastName} = useContext(LoggedUserContext);

    const dropDownButtonPressed = () => {
        if (activeDropDown === 'dropdown-toggle-off') {
            setActiveDropDown('dropdown-toggle-on');
        } else {
            setActiveDropDown('dropdown-toggle-off');
        }
    }

    const logout = ()=>{
        localStorage.clear();
        history.push('/');
    }

    return (
        <div>
            <div
                className={`dropdown js-dropdown-login ${activeDropDown}`}
            >
                <button
                    className="dropdown-btn js-dropdown-btn-login"
                    type="button"
                    id="dropdownMenuButton"
                    onClick={dropDownButtonPressed}

                >
                    
                    <FontAwesomeIcon icon={faUserMd} style={{ color: 'rgb(55,136,176)' }} size="lg" /><span className="dropdown-btn__label">{`  ${firstName} ${lastName} `}</span>
                    {/* {{$store.getters['userData/getEmail']}} */}
                    <span className="dropdown-arrow">
                        <svg
                            viewBox="0 0 128 128"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                        >
                            {/* <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com --> */}
                            <title>ico-chevron-down</title>
                            <desc>Created with Sketch.</desc>
                            <g
                                id="ico-chevron-down"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                            >
                                <path
                                    d="M60.652725,92.4861943 L16.593539,48.4270083 C14.4688203,46.3020683 14.4688203,42.8571769 16.593539,40.7324583 L21.7323162,35.5936811 C23.8537139,33.4722834 27.291742,33.4682983 29.4180104,35.584604 L64.5001107,70.5022085 L99.5819896,35.584604 C101.708258,33.4682983 105.146286,33.4722834 107.267684,35.5936811 L112.406461,40.7324583 C114.53118,42.8573983 114.53118,46.3022897 112.406461,48.4270083 L68.3474964,92.4861943 C66.2225564,94.610913 62.777665,94.610913 60.652725,92.4861943 Z"
                                    id="C"
                                    fill="#000000"
                                    fillRule="nonzero"
                                />
                            </g>
                        </svg>
                    </span>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Link className="dropdown-item" to="/personalInformation"
                    onClick={()=>{setActiveDropDown('dropdown-toggle-off');}}
                    >Account</Link>

                    <a className="dropdown-item" href=""
                    onClick={logout}
                    >Logout</a>
                    
                </div >
            </div >
        </div>
    );
}

export default UserDropDown;