import React, { useContext, useState } from 'react';
import { ProvidersContext } from '../../contexts/ProvidersContext';
import history from '../../history';
import graphQL from '../../apis/graphQL';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import { Link } from 'react-router-dom';

const ProviderRow = (props) => {
    const { firstName, lastName, npi, rol, status, disconnect, acceptConnection, acceptConnectionNumber, edit, viewProvider } = props;
    // loading
    const [loading, setLoading] = useState(false);

    const { setNpi, setCurrentNpiName } = useContext(ProvidersContext);
    const { setConnectedProviders } = useContext(LoggedUserContext);

    const goToDashboard = async (npi, firstName, lastName) => {

        setLoading(true);
        const response = await graphQL(`query{
            setCurrentNpi(npi: ${parseInt(npi)},name:"${firstName} ${lastName}"){
                success
                message
            }
            }`);
        setLoading(false);
        if (response.setCurrentNpi) {
            setNpi(parseInt(npi));
            setCurrentNpiName(`${firstName} ${lastName}`);
            history.push('/billing');
        } else if (response) {
            setLoading(false);
            // setGraphQLErrors(response);
        }
    }

    const disconnectNpi = async (npi) => {
        setLoading(true);
        const response = await graphQL(`query{
            removeNpi(npi: ${parseInt(npi)}){
                npi
                firstName
                lastName
                status
            }
            }`);
        setLoading(false);
        if (response.removeNpi) {
            setConnectedProviders(response.removeNpi);
        } else if (response) {
            setLoading(false);
            setConnectedProviders([]);
        }
    }


    return (

        <tr className="clickable-row" onClick={
            () => {
                if (viewProvider) {
                    goToDashboard(npi, firstName, lastName)
                }
            }
        } >
            <td>
                <strong>{`${firstName} ${lastName}`}</strong>
                <br />
                <span className="npi">NPI: {npi || 'npi'}</span>
            </td>
            <td>{rol || 'rol'}</td>
            <td>{status || ''}</td>
            <td>
                {disconnect ?
                    <a onClick={(e) => { e.stopPropagation(); disconnectNpi(npi); }}
                        className="btn btn-secondary"
                    //@click="disconnectProvider(provider.npi,index)"
                    >Disconnect</a> : <span></span>
                }
                {acceptConnection ? <Link to="/main" className="btn btn-primary">Accept Connection</Link> : <span></span>}
                {edit ? <Link to="/main" className="btn btn-secondary small">Edit</Link> : <span></span>}
                {acceptConnectionNumber ? <span className="btn-group">
                    <a href="#" className="btn btn-green btn-no-width">
                        1
                    <i className="far fa-comment"></i>
                    </a>
                    <Link to="/main" className="btn btn-secondary btn-no-width pr-1 pl-1">Accept Connection</Link>
                </span> : <span></span>}
            </td>

            <td className="text-center">
                {viewProvider ?
                    <a
                        onClick={() => { goToDashboard(npi, firstName, lastName) }}
                        href="#"
                        className="p-primary"
                    //    @click="viewProviderDashboard(provider.npi,provider.data.name_associate)"
                    >
                        {loading ? <div className="loader"></div> :
                            <strong>
                                View this
                                provider
                    </strong>
                        }
                    </a>
                    : <span></span>}
            </td>
        </tr>

    );
}

export default ProviderRow;