import React, { useContext, useEffect } from 'react';
import Input from '../../reusable/Input';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import history from '../../../history';
import { Link } from 'react-router-dom';
import AuthContextProvider from '../../../contexts/AuthContext';


const PermissionVerificationPMG = () => {

    const { npi } = useContext(ProvidersContext);

    // useEffect(() => {
    //     if (npi === '') {
    //         history.push('/selectProviderType');
    //     }
    // }, [npi]);

    return (
        <AuthContextProvider>
            <div>
                <div className="row indented gap-double row--centered">
                    <div className="col"><h2>Permission verification to access this PMG information. </h2></div>
                    <div className="col-grow-3">
                        <ul className="wizards-steps ml-3">
                            <li><span className="sr-only ">Step 1</span></li>
                            <li><span className="sr-only ">Step 2</span></li>
                            <li className="current"><span className="sr-only ">Step 3</span></li>
                            <li><span className="sr-only ">Step 4</span></li>
                            <li><span className="sr-only ">Step 5</span></li>
                        </ul>
                    </div>
                </div>

                <div class="container-with-sidebar__content-block">
                    <div class="row gap-triple">
                        <div class="col instructions">
                            <p>You will be assigned as the <strong>Primary Representative</strong> to this provider. It will allow you to see its billing information an also manage who else has permission to view it.</p>

                            <p>In order to proctect this providers information, you must provide a correct answer to each of the
                    questions asked. </p>
                            <p><img src="images/ilustration-verification-id.png" alt="Search Provider" class="mb-2 mt-2" /></p>
                            <p class="text-bold">Not a Primary Representative?</p>
                            <p>There must be a Primary Representative managing permissions for this provider. After a Primary Representative is registered, you can ask for permission to access this providers information. </p>
                        </div>
                        <div class="col-grow-3">
                            <p class="text-bold mb-2">Provider Information</p>

                            {/* <p class="message message-error">You have one or more wrong answers. Check your responses and try again.</p> */}

                            <div class="form-group mt-3">
                                <label for="taxid">Federal Tax Id:</label>
                                <input type="text" readonly value="1234567890" />
                            </div>

                            <div class="form-group">
                                <label for="organizationlegal">Organization Legal Name:</label>
                                <input type="text" id="organizationlegal" class="input-w300" placeholder="Ex. Grupo Médico Puerto Rico" />
                            </div>

                            <div class="form-group">
                                <label for="npi">NPI:</label>
                                <input type="text" placeholder="1234567890" />
                            </div>

                            <div class="form-group">
                                <label></label>
                                <input type="checkbox" name="termsagree" value="0" />
                                <span class="note-aside">I don’t have an NPI</span>
                            </div>

                            <p class="text-bold mb-2 mt-3">PMG Administrator:</p>

                            <div class="form-group">
                                <label for="firstname">First Name:</label>
                                <input type="text" id="firstname" class="input-w300" placeholder="" />
                            </div>
                            <div class="form-group">
                                <label for="amiddlename">Middle Name:</label>
                                <input type="text" id="middlename" class="input-w300" placeholder="" />
                            </div>

                            <div class="form-group">
                                <label for="lastname">Last Name:</label>
                                <input type="text" id="lastname" class="input-w300" placeholder="" />
                            </div>

                            <p class="text-bold mb-2 mt-3">Phone:</p>

                            <div class="form-group">
                                <label for="workphone">Principal Work Phone:</label>
                                <input class="input-w200" type="text" id="workphone" placeholder="Ex. (787) 000-0000" />

                                <input class="input-w100" type="text" id="ext" placeholder="Ex. 0000" />
                            </div>

                            <p class="text-bold mb-2 mt-3">Address:</p>

                            <div class="form-group">
                                <label for="addressline1">Address line 1:</label>
                                <input type="text" id="addressline1" class="input-w300" placeholder="Street and number." />
                            </div>
                            <div class="form-group">
                                <label for="addressline2">Address line 2:</label>
                                <input type="text" id="addressline2" class="input-w300"
                                    placeholder="Apartment, suite, unit, etc.." />
                            </div>

                            <div class="form-group">
                                <label for="addressline3">Address line 3:</label>
                                <input type="text" id="addressline3" class="input-w300"
                                    placeholder="Apartment, suite, unit, etc.." />
                                <span class="optional">(Optional)</span>
                            </div>
                            <div class="form-group">
                                <label for="city">City or Town:</label>
                                <input type="text" class="input-w200" id="city" placeholder="" />
                            </div>

                            <div class="form-group">
                                <label for="city">State or Territory:</label>
                                <input type="text" class="input-w200" id="state" placeholder="" />
                            </div>

                            <div class="form-group">
                                <label for="zip">Zip:</label>
                                <input type="text" id="zip" class="input-w100" placeholder="Ex. 0000" />
                            </div>
                            <div class="form-group mt-3">
                                <Link to="/selectProviderType" class="btn btn-secondary mr-2">Cancel</Link>
                                <button  class="btn btn-primary" onClick={()=>{
                                    history.push('/disclosure');
                                }}>Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </AuthContextProvider>
    );
}

export default PermissionVerificationPMG;
