import React from 'react';

const TermsAndConditions = (props) => {
    return (<div>
        <h2>Terms & Conditions</h2>
        <div>
            <p>
                Thank you for your interest in registering and using the Provider Portal of the Puerto Rico
                Health Insurance Administration (“ASES,” for its Spanish acronym). This service is offered
                exclusively for Providers of the ASES Vital Program and their Authorized Representatives. Please
                review and agree to the following terms and conditions before proceeding.
          </p>
            <div className="important-notice">
                <h3 className="text-center">IMPORTANT NOTICE</h3>
                <p>
                    You are accessing a Puerto Rico Government information system, which for purposes of
                    compliance with the requirements herein, particularly as to the applicability of authorized
                    and proper use requirements regarding the information accessed, includes: (1) this computer,
                    (2) this computer network, (3) all computers connected to this network, and (4) all devices
                    and storage media attached to this network or to a computer on this network.
            </p>
                <p>
                    This information system is provided for Puerto Rico Government-authorized use only.
              <strong>
                        Unauthorized or improper use of this system may result in disciplinary action, as
                        well as civil and criminal penalties.
              </strong>
                </p>
                <p>By using this information system, you understand and consent to the following:</p>
                <ul>
                    <li>
                        There is no reasonable expectation of privacy regarding any communication or data
                        transmitted through or stored on this information system. At any time, and for any
                        lawful Government purpose, the Government may monitor, intercept, and search and seize
                        any communication or data transmitted through or stored on this information system.
              </li>
                    <li>
                        Any communication or data transmitted through or stored on this information system may
                        be disclosed or used by ASES, Government agencies, or their authorized designees for any
                        lawful Government purpose.
              </li>
                </ul>
            </div>
            <p>
                ASES is committed to the protection of the privacy of Vital Providers when accessing and using
                our website. Reasonable best efforts are made to ensure that the information you submit is used
                only for purposes intended for the use of this Portal, and otherwise remains private to the
                extent feasible. During the registration process, specific information may be requested from
                you. ASES does not disclose any information provided in connection with those requests to any
                outside parties, except to manage the Vital Program or as required by law.
          </p>
            <p>
                Providers should designate on the Portal a Primary Representative from their office. This Primary
                Representative will have the ability to add Authorized Representatives within their office to
                the Portal.
          </p>
            <p>
                Registration Instructions: Both the information below and acceptance of the attached Agreement is
                required in order to complete registration.
            <strong>
                    If you have any questions, please contact
                    Customer Service at 787-474-3300 or 1-800-981-2737.
            </strong>
            </p>
            <h3>Definitions</h3>
            <p>The terms below as used in this Agreement shall be defined as follows:</p>
            <ul>
                <li>
                    <strong>“Administrator”</strong> means the Puerto Rico Health Insurance Administration
                    (“ASES,” by its Spanish acronym).
            </li>
                <li>
                    <strong>“Authorized Representative"</strong> means an individual that a Provider has
                    authorized to use the Portal on Provider’s behalf.
            </li>
                <li>
                    <strong>“Member”</strong> means the person who is receiving health care items or services
                    under the Vital program.
            </li>
                <li>
                    <strong>“MCO(s)”</strong> means the managed care organizations contracted with the
                    Administrator under the Vital program.
            </li>
                <li>
                    <strong>“Vital”</strong> means the government managed care program operated by ASES by a
                    Memorandum of Understanding with the Puerto Rico Medicaid Program, under the Puerto Rico
                    Department of Health, for the provision of health care items and services to Members.
            </li>
                <li>
                    <strong>“Primary Representative”</strong> means the Authorized Representative in the
                    Provider’s office who has responsibility for adding, deleting and maintaining the names of
                    the Provider’s Authorized Representatives.
            </li>
                <li>
                    <strong>“Provider”</strong> means the signatory to this Agreement, contracted to provide
                    health care items or services to Vital Members.
            </li>
                <li>
                    <strong>“Service”</strong> means the authorized access and use of the Secure Web Portal and
                    supporting website under this Agreement.
            </li>
                <li>
                    <strong>“Treatment”</strong> means, in accordance with 45 CFR 164.501, the provision,
                    coordination, or management of health care and related services by one or more health care
                    providers, including the coordination or management of healthcare by a health care provider
                    with a third party; consultation between health care providers relating to a patient; or the
                    referral of a patient for health care from one health care provider to another.
            </li>
            </ul>
            <h3>Introduction</h3>
            <p>
                This Provider Secure Web Portal Agreement (“Agreement”) contains the terms and conditions that
                govern the use of this Secure Web Portal Service between the Administrator and an authorized
                user(s) for access to information on eligibility, claims payments, prior authorization, and
                other authorized functions. ASES maintains this site in connection with its administration of
                the Vital program in Puerto Rico.
          </p>
            <h3>Use of the Secure Web Portal Service</h3>
            <p>
                The Secure Web Portal Service provides access to eligibility and health information of Members of
                the Vital Program for authorized users. The Provider shall use this Service solely in connection
                with its provision of health care items or services to Members of the Vital program. All users,
                including Providers, Primary Representative(s), and Authorized Representative(s), shall use the
                portal solely within the appropriate scope and conduct of his or her employment or agency with
                Provider. Users shall access and use the portal subject to the following:
          </p>
            <ol>
                <li>The terms and conditions of this Agreement; and</li>
                <li>
                    The applicable provisions of Provider’s contract with MCOs contracted by ASES to provide
                    health care items and services to Vital Members (the “Provider Contract”). The applicable
                    provisions of the Provider Contract include, but are not limited to, the use and disclosure
                    of protected health information under the HIPAA privacy standards, member eligibility
                    verification, utilization management standards as set forth within MCO policies and the
                    Provider Handbook, and timelines for submission and resubmission of claims.
            </li>
            </ol>
            <p>
                In the event of a conflict between this Agreement and the Provider Contract, this Agreement shall
                govern.
          </p>
            <p>
                Provider shall require the Primary Representative and Authorized Representative(s) to (1) keep
                confidential and not disclose the Provider’s password to any person except Provider or the
                Primary or Authorized Representative; (2) use the Service solely in connection with Provider’s
                provision of health care items and services to Members of the Vital program, and solely within
                the appropriate scope and conduct of his or her employment or agency with Provider; and (3) use
                the Service pursuant to the terms and conditions of this Agreement and the Provider Contract.
                Provider shall be deemed ultimately responsible for the Primary Representative and Authorized
                Representative(s) of the Service.
          </p>
            <p>
                Upon discovery that the Primary or Authorized Representative(s) (1) has violated any of the
                conditions set forth above, or (2) no longer works for or represents Provider, Provider shall
                immediately revoke such Representative’s authority to use the Service on behalf of the Provider.
                Provider shall also notify the Administrator when it has revoked a Primary Representative’s or
                Authorized Representative’s authority to use the Service for any other reason. The Provider
                shall revoke the Primary Representative’s or Authorized Representative’s authority to use the
                Service if directed to do so by Administrator.
          </p>
            <p>
                If a Primary or Authorized Representative(s)’ authority is revoked, the Provider shall
                immediately delete such person’s access to the Service and designate a new Primary
                Representative or Authorized Representative, as required, in accordance with the terms and
                conditions of this Agreement.
          </p>

            <h3>Use of Member Health Information</h3>
            <p>
                The Provider shall treat all Member health information displayed on the Member Care Information
                Portal or otherwise made available through the Service in accordance with the applicable
                provisions of the HIPAA Privacy Standards, 42 CFR Part 2, and any other applicable federal or
                Puerto Rico laws, regulations or guidance governing the privacy and security of health
                information.
          </p>
            <p>
                With respect to Member health information relating to the treatment of drug or alcohol abuse or
                addiction, this information has been disclosed to you from records protected from certain
                disclosures by applicable federal laws, regulations and guidance on patient confidentiality,
                including but not limited to 42 CFR Part 2. These federal rules prohibit you from making any
                further disclosure of this information unless further disclosure is expressly permitted by the
                written consent of the person to whom it pertains or as otherwise permitted by 42 CFR Part 2. A
                general authorization for the release of medical or other information is NOT sufficient for this
                purpose. The federal rules also restrict any use of this information to criminally investigate
                or prosecute a patient.
          </p>

            <h3>Changes to the Portal Service or this Agreement</h3>
            <p>
                The Administrator may, at any time, make changes to the Service, the terms and conditions under
                this Agreement, or any other applicable requirements that govern the use of the Service at any
                time. The Provider should review the Secure Web Portal and this Agreement periodically for any
                updates or changes. The Provider’s continued access or use of the Service shall be deemed as the
                Provider’s acknowledgement, notification and acceptance of these changes.
          </p>
            <h3>Warranties</h3>
            <p>
                The Administrator uses reasonable methods to provide accurate and current information on member
                eligibility, health information, claims submissions, and prior authorization(s) available
                through the Service. However, because of the possibility of technical and human error, as well
                as other factors, there is no implied warranty of any kind, including of representation about
                the accuracy, completeness, or appropriateness or fitness for a particular purpose of this
                Service. While the Administrator uses reasonable methods to secure the Secure Web Portal, there
                is no warranty that the Service will be free from corrupted data, computer viruses or similar
                destructive or contaminating code.
          </p>
            <p>
                IN PARTICULAR, THE MEMBER HEALTH INFORMATION APPEARING ON THE PORTAL MAY NOT REPRESENT A FULL OR
                ACCURATE PICTURE OF A MEMBER’S HEALTH HISTORY. PROVIDERS SHOULD RELY UPON ALL AVAILABLE SOURCES
                OF INFORMATION BEFORE RECOMMENDING, IN THEIR OWN PROFESSIONAL JUDGMENT, ANY COURSE OF TREATMENT
                FOR A MEMBER.
          </p>
            <h3>Liabilities</h3>
            <p>
                The Administrator shall not be held liable for any known or unknown delay, difficulty in use,
                inaccuracy or incompleteness of information, computer viruses, malicious code, loss of data,
                compatibility issues, or otherwise. Providers are to use this Service at its own risk. The
                Administrator will not be liable for any direct, indirect, incidental, consequential, or
                punitive damages arising out of Provider’s use of or access to the Service or any other linked
                or accompanying websites or functions. By its use of the Service, the Provider accepts that the
                Service may not perform as intended despite the best efforts of the Administrator, the Provider,
                or any internet service providers.
          </p>
            <h3>Ownership, License and Restrictions on Use of Materials</h3>
            <p>
                As between the Administrator and the Provider, all rights, titles and interests (including all
                copyrights, trademarks and other intellectual property rights) to and in the Service belong to
                the Puerto Rico Health Insurance Administration.
          </p>
            <p>
                The Provider is hereby granted a nonexclusive, nontransferable, limited license to view and use
                information retrieved through the Service solely in connection with its provision of health care
                items or services to the Members of Vital. Except as expressly stated above, no part of the
                information made available through the Service, including but not limited to materials retrieved
                from it and the underlying code, may be reproduced, republished, copied, transmitted, sold or
                distributed in any form or by any means unless as expressly permitted by law. In no event shall
                materials from this Portal be stored in any information storage and retrieval system of the
                Provider or any other entity without prior written permission from the Administrator.
          </p>
            <p>
                The Provider’s use of this Service allows the Administrator to gather certain limited information
                about the Provider and its use of the Service. The Provider agrees and consents to the
                Administrator’s use of such information in aggregated form.
          </p>
            <h3>Site System Integrity</h3>
            <p>
                The Provider may use the Portal for normal use in connection with its provision of health care
                items or services to Members of Vital. The Provider may not use any device, software, routine,
                or agent to interfere or attempt to interfere with the proper function and use of the Service.
                The Provider may not take any action that imposes an unreasonable or disproportionately large
                load on the underlying technical or operational infrastructure of the Service. The Provider may
                not disclose or share passwords to or with third parties, except with an Authorized
                Representative, or use or allow the use of passwords for any unauthorized purpose. The Provider
                shall take reasonable precautions to secure passwords from any unauthorized use. The Provider
                may not attempt to log-in to use the Service with a user name or password other than its own.
          </p>
            <p>
                Continuous, uninterrupted access to the Service is not guaranteed. Numerous actions beyond the
                Administrator’s control may interfere with the use of the Service.
          </p>
            <h3>Service Restriction</h3>
            <p>
                Subject to all applicable laws and regulations, the Administrator reserves the right to suspend
                or deny Provider’s access to all or any portion of the Service for cause in its sole discretion
                with or without notice. The Provider agrees that suspension or termination of the Provider’s
                access to the Service may be effected immediately and without prior notice. Further, the
                Provider agrees that the Administrator shall not be liable to the Provider or any third party
                for any suspension or termination of the Provider’s access to the Service.
          </p>
            <h3>Agreement Termination</h3>
            <p>
                Either party may terminate this Agreement at any time. The Administrator may also immediately
                suspend or terminate this Agreement with the Provider and Provider’s access to the Service if,
                in the sole discretion of the Administrator, the Provider breaches this Agreement.
          </p>
            <p>
                Upon termination of this Agreement, the Provider agrees to destroy all materials obtained from
                use of the Portal site, as well as all related documentation, copies, and installations, whether
                or not made in accordance with the terms and conditions set forth under this Agreement.
          </p>
            <p>
                The person(s) signing this Agreement warrants that he or she has full authority to do so and that
                the signature below binds the Provider, including the Provider’s owners, employees, agents and
                representatives, on whose behalf the person below signs.
          </p>
        </div>
    </div>);
}

export default TermsAndConditions;