import React, { useRef, useEffect, useContext, useState } from 'react';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import numeral from 'numeral';
import { ProvidersContext } from '../../contexts/ProvidersContext';

const PieChart = (props) => {
    // table
    const [table, setTable] = useState('');
    const { data, dollars } = props;
    const { npi } = useContext(ProvidersContext);

    const graphCanvas = useRef();

    useEffect(() => {

        let pieChartColors = [];
        const pieChartLabels = data.map((data) => {

            switch (data.carrierName || data.category) {
                case "First Medical":
                    pieChartColors.push('rgba(255,100,131,1)');
                    break;
                case "MMM Multi Health":
                    pieChartColors.push('rgba(255,159,64,1)');
                    break;
                case "Molina Health Care":
                    pieChartColors.push('rgba(255,205,86,1)');
                    break;
                case "Plan de Salud Menonita":
                    pieChartColors.push('rgba(54,162,235,1)');
                    break;
                case "Triple S":
                    pieChartColors.push('rgba(74,192,192,1)');
                    break;
                case "Demographic":
                    pieChartColors.push('rgba(255,100,131,1)');
                    break;
                case "High Cost High Need":
                    pieChartColors.push('rgba(255,159,64,1)');
                    break;
                case "Dual Eligible":
                    pieChartColors.push('rgba(255,205,86,1)');
                    break;
                case "Special":
                    pieChartColors.push('rgba(54,162,235,1)');
                    break;
                case "Platino":
                    pieChartColors.push('rgba(74,192,192,1)');
                    break;
                default:
                // code block
            }
            return data.carrierName || data.category;
        });

        let total = 0;
        const pieChartData = data.map((data) => {
            total += data.populationByMCO || data.populationByCategory;
            return data.populationByMCO || data.populationByCategory;
        });

        if (props.showTable) {
            let ta = pieChartLabels.map((label, x) => {
                if (x === pieChartLabels.length - 1) {
                    return (
                        <React.Fragment key={x}>
                            <tr >
                                <td>{label}</td>
                                <td className="text-right">${numeral(pieChartData[x]).format('0,0.00')}</td>
                                <td className="text-right">{numeral(pieChartData[x] / total * 100).format('0,0.00')}</td>
                            </tr>
                            <tr className="text-bold">
                                <td>Total</td>
                                <td className="text-right">${numeral(total).format('0,0.00')}</td>
                                <td className="text-right">100%</td>

                            </tr>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <tr key={x}>
                            <td>{label}</td>
                            <td className="text-right">${numeral(pieChartData[x]).format('0,0.00')}
                            </td>
                            <td className="text-right">{numeral(pieChartData[x] / total * 100).format('0,0.00')}</td>
                        </tr>
                    )
                }
            });
            setTable(ta);
        } else {
            new Chart(graphCanvas.current, {
                plugins: [ChartDataLabels],
                type: 'pie',
                data: {
                    labels: pieChartLabels,
                    datasets: [{
                        label: 'Providers',
                        data: pieChartData,
                        backgroundColor: pieChartColors,
                        borderWidth: 2
                    }]
                },
                options: {
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                // data for manipulation
                                if (dollars) {
                                    return (
                                        ' $' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0.00')
                                    );
                                }
                                return (
                                    ' ' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0')
                                );
                            },
                        },
                    },
                    legend: {
                        align: 'start',
                        display: true,
                        labels: {
                            fontColor: 'rgb(0, 0, 0)',
                            fontSize: 10
                        },
                        position: 'right',
                    },
                    plugins: {
                        datalabels: {
                            color: "#000000",
                            formatter: function (value) {
                                return numeral(value / total * 100).format('0,0.00') + '%';
                            },
                            font: {
                                weight: 'bold',
                                size: 10,

                            }
                        }
                    }
                }
            });
        }
    }, [npi]);

    if (props.showTable) {
        if (table === '') {
            return <tr></tr>
        }
        return <React.Fragment>{table}</React.Fragment>;
    } else {
        return (
            <div>
                <canvas ref={graphCanvas} />
            </div>
        );
    }
}

export default PieChart;