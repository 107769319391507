import React, { useState, useEffect, useContext } from 'react';
import Input from '../../reusable/Input';
import Select from '../../reusable/Select';
import graphQL from '../../../apis/graphQL';
import history from '../../../history';
import { Link } from 'react-router-dom';
import { RegistrationContext } from '../../../contexts/RegistrationContext';

const options = [
    { value: '1', label: 'I\'am a provider' },
    { value: '2', label: 'I represent a provider' },
    { value: '3', label: 'Both' }
]

const Step1 = () => {

    // profile, email and password will be managed from context because will be used in the next step of the registration process
    const { profile, email, password, setProfile, setEmail, setPassword } = useContext(RegistrationContext);
    // disableButton 
    const [disableButton, setDisableButton] = useState(true);
    // confirmPassword
    const [confirmPassword, setConfirmPassword] = useState('');
    // emailError
    const [emailError] = useState(false);
    // profileError
    const [profileError] = useState(false);
    // passwordError
    const [passwordError, setPasswordError] = useState(false);
    // confirmPasswordError
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState([]);

    const passwordRegexValidation = () => {
        let errorMessage = [];
        //Contains a number validation
        var regex = /[0-9]/g;
        if (regex.exec(password) == null) {
            errorMessage.push("Password must contain a number");
        }
        //length validation needs 8 characters mininum
        if (password.length < 8) {
            errorMessage.push("Password must at least 8 characters long");
        }
        //Does not contain email username
        if (email != null && email.includes("@")) {
            let username = email.match(/^([^@]*)@/)[1];
            if (password.includes(username)) {
                errorMessage.push("Password cannot contains your username");
            }
        }
        //Contains at least one special character
        regex = /^[a-zA-Z0-9 ]*$/;
        if (regex.exec(password) != null) {
            errorMessage.push("Password must contain at least a special character");
        }
        if (errorMessage.length === 0) {
            setPasswordError(false);
        } else {
            setPasswordError(errorMessage.map((error, index) => {
                return <p key={index}>{error}</p>
            }));
        }
    }

    //Set profile when selection changes
    const onProfileChange = selectedOption => {
        setProfile(selectedOption.value);
    };

    // Monitor fields and enable the continue button when all fields aren't empty
    useEffect(() => {
        if (profile === '' || email === '' || password === '' || confirmPassword === '' || graphQLErrors.length > 0 || passwordError.length > 0) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [profile, email, password, confirmPassword, graphQLErrors, passwordError]);

    //Every time that password field changes, run the regex verification; if password isn't empty
    useEffect(() => {
        if (password !== '') {
            passwordRegexValidation();
        } else {
            setPasswordError(false);
        }
    }, [password]);

    const onContinueButtonClicked = () => {
        //Check that the passwords are equal
        if (password !== confirmPassword) {
            setConfirmPasswordError('Password and Confirm Password needs to be equal');
            return;
        } else {
            setConfirmPasswordError(false);
            setProfile(profile);
            setEmail(email);
            setPassword(password);
            history.push('/createAccountStep2');
        }
    }

    //onChange fields
    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    }

    //onBlur email verifies if the email is valid and if the email exists in the database
    const onBlur = async (e) => {
        // Reset Errors
        setGraphQLErrors([]);
        const response = await graphQL(`query{
            verifyIfEmailExists(email: "${e.target.value}"){
                success
                message
            }
        }`);
        if (response.verifyIfEmailExists) {
            //is all ok
        } else if (response) {
            setGraphQLErrors(response);
        }
    }

    return (
        <div>
            <div className="container">
                <div className="row indented gap-triple">
                    <div className="col instructions">
                        <h2>New account</h2>
                        <p>You must create a personal account to access the portal.</p>
                        <p>With your user account you can connect the providers to which you are authorized to manage their information.</p>
                        <p>
                            <img
                                src="/images/users-connection-diagram.png"
                                alt="An account cant connect to an Individual or Organizational provider or a Medical Group"
                                className="mb-2 mt-2"
                            />
                        </p>
                        <p>Once your account is created, additional security information will be required in order to enable the full functionality of the portal.</p>
                    </div>
                    <div className="col-grow-3">
                        <form action="sign-in-step2.html">
                            <p
                                className="text-bold mb-2"
                            >Are you a provider or will you represent one or more providers?</p>
                            <Select
                                label="Profile:"
                                id="profile"
                                options={options}
                                errorMessage={profileError}
                                onChange={onProfileChange}
                                customClass="input-w400"
                            />
                            <p
                                className="text-bold mt-3 mb-2"
                            >Enter the email you will use as a user to access your account.</p>
                            {/* <!-- Email --> */}
                            <Input
                                type="text"
                                label="Email:"
                                id="email"
                                placeholder="myemail@email.com"
                                errorMessage={emailError ? emailError : graphQLErrors.length === 0 ? false : graphQLErrors}
                                customClass="input-w400"
                                onBlur={onBlur}
                                onChange={onChange}
                            />

                            <p className="text-bold mt-3 mb-2">
                                Now create the password with which you will access your account.
                            </p>
                            {/* password field */}
                            <Input
                                type="password"
                                label="Password:"
                                id="password"
                                placeholder=""
                                errorMessage={passwordError}
                                onChange={onChange}
                            />
                            {/* confirmPassword field */}
                            <Input
                                type="password"
                                label="Confirm Password:"
                                id="confirmPassword"
                                onChange={onChange}
                                placeholder=""
                                errorMessage={confirmPasswordError}
                            />
                            {/* continue and cancel buttons */}
                            <div className="form-group mt-3">
                                <Link to={`/`} className="btn btn-secondary mr-2">Cancel</Link>
                                <button
                                    type="button"
                                    disabled={disableButton}
                                    className="btn btn-primary"
                                    onClick={onContinueButtonClicked}
                                >Continue</button>
                            </div>
                        </form >
                    </div >
                </div >
            </div >
        </div >
    );
}
export default Step1;