import React, { useState, useContext } from 'react';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import history from '../../history';
import { ProvidersContext } from '../../contexts/ProvidersContext';
import { Link } from 'react-router-dom';
import graphQL from '../../apis/graphQL';

const ProvidersDropDown = (props) => {
    // activeDropDown
    const [activeDropDown, setActiveDropDown] = useState('dropdown-toggle-off');

    const { connectedProviders } = useContext(LoggedUserContext);

    const { setNpi, setCurrentNpiName, currentNpiName } = useContext(ProvidersContext);

    const dropDownButtonPressed = () => {
        if (activeDropDown === 'dropdown-toggle-off') {
            setActiveDropDown('dropdown-toggle-on');
        } else {
            setActiveDropDown('dropdown-toggle-off');
        }
    }

    const changeProvider = async (npi, firstName, lastName) => {

        const response = await graphQL(`query{
            setCurrentNpi(npi: ${parseInt(npi)},name:"${firstName} ${lastName}"){
                success
                message
            }
            }`);
        if (response.setCurrentNpi) {
            setCurrentNpiName(`${firstName} ${lastName}`);
            setNpi(npi);
            setActiveDropDown('dropdown-toggle-off');
            if (props.selectProvider || history.location.pathname === '/providersNetworkAndPopulation') {
                history.push('/billing');
            }
        } else if (response) {
            // setGraphQLErrors(response);
        }
    }

    const renderConnectedProviders = () => {
        return connectedProviders.map((cp) => {
            return (
                <a key={cp.npi} href="#" onClick={() => changeProvider(cp.npi, cp.firstName, cp.lastName)} className="dropdown-item">{cp.firstName} {cp.lastName}</a>
            )
        })
    }

    return (
        <div>
            <div
                className={`dropdown js-dropdown-provider ${activeDropDown}`}
            >
                <button
                    className="dropdown-btn dropdown-btn--text-big js-dropdown-btn-login"
                    type="button"
                    id="dropdownMenuButton"
                    onClick={dropDownButtonPressed}
                > {props.selectProvider ? 'SELECT PROVIDER' : currentNpiName}
                    <span className="dropdown-arrow">
                        <svg
                            viewBox="0 0 128 128"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                        >
                            {/* <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com --> */}
                            <title>ico-chevron-down</title>
                            <desc>Created with Sketch.</desc>
                            <g
                                id="ico-chevron-down"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                            >
                                <path
                                    d="M60.652725,92.4861943 L16.593539,48.4270083 C14.4688203,46.3020683 14.4688203,42.8571769 16.593539,40.7324583 L21.7323162,35.5936811 C23.8537139,33.4722834 27.291742,33.4682983 29.4180104,35.584604 L64.5001107,70.5022085 L99.5819896,35.584604 C101.708258,33.4682983 105.146286,33.4722834 107.267684,35.5936811 L112.406461,40.7324583 C114.53118,42.8573983 114.53118,46.3022897 112.406461,48.4270083 L68.3474964,92.4861943 C66.2225564,94.610913 62.777665,94.610913 60.652725,92.4861943 Z"
                                    id="C"
                                    fill="#000000"
                                    fillRule="nonzero"
                                />
                            </g>
                        </svg>
                    </span>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                    <Link to="/providersList" onClick={() => { setActiveDropDown('dropdown-toggle-off'); }} className="dropdown-item text-bold"
                    // @click="goToProvidersList()"
                    >Go to Providers List</Link>
                    {}
                    {connectedProviders.length > 0 ?
                        <div className="dropdown-divider"></div> : ''
                    }

                    {renderConnectedProviders()}

                </div >
            </div >
        </div>
    );
}

export default ProvidersDropDown;

