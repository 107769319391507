import React, { createContext, useState } from 'react';

export const ProvidersContext = createContext();

const ProvidersContextProvider = (props) => {

    // npi
    const [npi, setNpi] = useState('');
    // npiThatNeedsAuthorization
    const [npiThatNeedsAuthorization, setNpiThatNeedsAuthorization] = useState('');
    // npiNameThatNeedsAuthorization
    const [npiNameThatNeedsAuthorization, setNpiNameThatNeedsAuthorization] = useState('');
    // npiPrimaryRepresentative
    const [npiPrimaryRepresentative, setNpiPrimaryRepresentative] = useState('');
    // currentNpiName
    const [currentNpiName, setCurrentNpiName] = useState('');
    // enumerationType
    const [enumerationType, setEnumerationType] = useState('');
    // firstName
    const [firstName, setFirstName] = useState('');
    // lastName
    const [lastName, setLastName] = useState('');

    return (
        <ProvidersContext.Provider value={{ npi, setNpi, npiThatNeedsAuthorization,setNpiThatNeedsAuthorization, npiNameThatNeedsAuthorization,npiPrimaryRepresentative,setNpiPrimaryRepresentative,setNpiNameThatNeedsAuthorization, enumerationType, setEnumerationType, firstName, setFirstName, lastName, setLastName, currentNpiName, setCurrentNpiName }}>
            {props.children}
        </ProvidersContext.Provider>
    );
}

export default ProvidersContextProvider;