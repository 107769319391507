import React, { useContext } from 'react';
import AuthContextProvider from '../../../contexts/AuthContext';
import { LoggedUserContext } from '../../../contexts/LoggedUser';
const AccountSuspended = () => {

    const { reason, additionalReason } = useContext(LoggedUserContext);

    return (
        <AuthContextProvider>
            <div>
                <div className="container-with-sidebar__heading-bar sticky">
                    <h1 className="container-with-sidebar__title">Account Information</h1>
                </div>
                <section className="container-with-sidebar__content-block">
                    <div className="row indented gap-triple">
                        <div className="col instructions">
                            <p><img src="images/ilus-suspen.png" alt="Illustration personal information"
                                className="mb-2 mt-2" /></p>
                        </div>
                        <div className="col-grow-2">
                            <p className="message message-error">Your account is suspended</p>
                            <p>Your account has been suspended for the following reason:</p>
                            <p>{reason} {additionalReason}</p>
                            <p>Instructions for recovering/revising account to be determined. </p>
                        </div>
                        <div className="col"></div>
                    </div>
                </section>
            </div>
        </AuthContextProvider>
    );
}

export default AccountSuspended;