import ReactGA from "react-ga";

const initGA = () => {
    ReactGA.initialize("UA-156022292-1");
}

export const SaveLoginActionToGoogleAnalytics = (userId) => {
    initGA();
    ReactGA.event({
        category:'User Actions',
        action: 'User Logged',
        label: userId
    });
};