import React from 'react';
import { Link } from 'react-router-dom';

const AddConnectionConfirmed = (props) => {
    return (
        <div>
            <div className="container-with-sidebar__heading-bar sticky"><div className="row row--centered"> <div className="col-grow-2"><h2>Request Sent</h2></div>
                <div className="col-grow-2">
                    <ul className="wizards-steps ml-3">
                        <li><span className="sr-only ">Step 1</span></li>
                        <li className="current"><span className="sr-only ">Step 2</span></li>
                    </ul>
                </div>
                <div className="col text-center">
                    {/* <Link to="/providerConnections" className="btn btn-link">Cancel</Link> */}
                </div>
            </div>
            </div>
            <div className="container-with-sidebar__content-block">
                <div className="row indented gap-double">
                    <div className="col instructions">
                        <p>
                            <img src="images/image-request-sent.png" alt="Depicting paper plane" className="mb-2 mt-2" /></p>
                    </div>
                    <div className="col-grow-3">
                        <p className="text-bold mb-2 p-primary">Authorization Sent</p>
                        <p className="mb-2 p-primary">{props.location.state.email || ''}</p>
                        <p className="text-bold mb-2 p-primary">User must create an account and connect this provider. <br /> He/She will be already authorized to access. </p>
                        <p><Link to="/providerConnections" className="btn btn-primary">Back to Connections</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddConnectionConfirmed;