import React, { createContext, useState } from 'react';


export const TermsAndAgreementsContext = createContext();

const TermsAndAgreementsContextProvider = (props) => {

    // protectMain
    const [protectMain, setProtectMain] = useState(true);

    return (
        <TermsAndAgreementsContext.Provider value={{ protectMain, setProtectMain }}>
            {props.children}
        </TermsAndAgreementsContext.Provider>
    );
}

export default TermsAndAgreementsContextProvider;