import React, { useEffect, useState } from 'react';
import graphQL from '../apis/graphQL';
import TrelloRow from './reusable/TrelloRow';
import AuthContextProvider from '../contexts/AuthContext';
import { default as S } from 'react-select';

const TrelloOpenTasks = () => {

    const filter = {
        id: 'filter',
        options: [
            {
                value: '',
                label: 'All'
            },
            {
                label: 'Design',
                value: 'Design'
            },
            {
                label: 'Development',
                value: 'Development'
            },
            {
                label: 'Bugs',
                value: 'Bugs'
            },
            {
                label: 'Doing',
                value: 'Doing'
            }
        ],
        defaultValue: {
            value: '',
            label: 'All'
        }
    };

    // optionSelected
    const [optionSelected, setOptionSelected] = useState(filter.defaultValue);
    // selectedFilterOption
    const [selectedFilterOption, setSelectedFilterOption] = useState('');
    // updateCounter
    const [updateCounter, setUpdateCounter] = useState(0);
    // openTasks
    const [openTasks, setOpenTasks] = useState([]);
    // openTasksFiltered
    const [openTasksFiltered, setOpenTasksFiltered] = useState([]);
    // loading
    const [loading, setLoading] = useState(true);
    // showFilterResultsText
    const [showFilterResultsText, setShowFilterResultsText] = useState(false);
    // disabledSearchButton
    const [disabledSearchButton, setDisabledSearchButton] = useState(true);
    // inputFilter
    const [inputFilter, setInputFilter] = useState('');
    // loadingScreen
    const [loadingScreen, setLoadingScreen] = useState(false);
    // initialNoSearch
    const [initialNoSearch, setInitialNoSearch] = useState(true);

    useEffect(() => {
        (async () => {
            setLoadingScreen(true);
            setLoading(true);
            const response = await graphQL(`mutation{
            trelloCardsInABoard{
                id
                idListName
                name
                desc
                labels{
                name
                color
                }
                shortUrl
            }
            }`);
            if (response.trelloCardsInABoard) {
                setLoadingScreen(false);
                console.log(response.trelloCardsInABoard);
                setOpenTasks(response.trelloCardsInABoard);
                setOpenTasksFiltered(response.trelloCardsInABoard);
            } else if (response) {
                setLoading(false);
            }
        })()
    }, [updateCounter]);

    const renderOpenTasks = () => {
        return openTasksFiltered.map((openTask, index) => {
            return <TrelloRow task={openTask} key={index} updateList={updateFromTrelloRow} />;
        })
    }

    const updateFromTrelloRow = () => {
        const u = updateCounter + 1;
        setUpdateCounter(u)
    }

    const onChange = (e) => {
        setShowFilterResultsText(false);
        setInputFilter(e.target.value);
        let filtered = [];
        if (e.target.value !== '' && selectedFilterOption === '') {
            filtered = openTasks.filter((task) => {
                return task.name.toLowerCase().includes(e.target.value) === true;
            });
        } else if (e.target.value !== '' && selectedFilterOption !== '') {
            filtered = openTasks.filter((task) => {
                return task.name.toLowerCase().includes(e.target.value) === true && task.idListName === selectedFilterOption;
            });
        } else if (e.target.value === '' && selectedFilterOption !== '') {
            filtered = openTasks.filter((task) => {
                return task.idListName === selectedFilterOption;
            });
        } else {
            filtered = openTasks;
        }
        setOpenTasksFiltered(filtered);
    }

    return (
        <AuthContextProvider>
            {loadingScreen ? <div class="loading" style={{ marginTop: '150px' }}><div class="loader-screen"></div></div> :
                <div>
                    <div className="utility-bar-wrapper container-with-sidebar__content-block" style={{ marginBottom: '-75px' }}>
                        <form className="utility-bar" >
                            <div className="utility-bar-col">
                                <span className="mr-1 p-primary text-bold"><i
                                    className="fas fa-search mr-1"></i>Open Task Search</span>

                                <div className="multiselect-linear-wrapper">
                                    {/* Filter Dropdown */}
                                    <label>Filter By List: </label>
                                    <S options={filter.options} className="select-form-combo" id={filter.id} name={filter.id} onChange={(option) => {
                                        setOptionSelected(option);
                                        setSelectedFilterOption(option.value)
                                        let filtered = [];
                                        if (inputFilter !== '' && option.value === '') {
                                            filtered = openTasks.filter((task) => {
                                                return task.name.toLowerCase().includes(inputFilter) === true;
                                            });
                                        } else if (inputFilter !== '' && option.value !== '') {
                                            filtered = openTasks.filter((task) => {
                                                return task.name.toLowerCase().includes(inputFilter) === true && task.idListName === option.value;
                                            });
                                        } else if (inputFilter === '' && option.value !== '') {
                                            filtered = openTasks.filter((task) => {
                                                return task.idListName === option.value;
                                            });
                                        } else {
                                            filtered = openTasks;
                                        }
                                        setOpenTasksFiltered(filtered);
                                    }
                                    }
                                        value={optionSelected}
                                    />
                                    {/* .Filter Dropdown */}
                                </div>
                                <label>Filter by Name: </label>
                                <input type="text" placeholder="Ex. 1234567890" id="inputFilter" name="inputFilter" onChange={onChange} />

                            </div>
                        </form>
                    </div>

                    <section className="container-with-sidebar__content-block">
                        <div className="panel panel-data mt-1">
                            <div className="panel-body">
                                <div className="table-scroll">
                                    <table className="providers-list">
                                        <tbody>
                                            <tr>
                                                <th>List</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Labels</th>
                                                <th>Short Url</th>
                                                <th>Actions</th>
                                            </tr>
                                            {renderOpenTasks()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div >
                    </section>
                </div>
            }
        </AuthContextProvider>
    );
}

export default TrelloOpenTasks;