import React, { useRef, useEffect, useContext } from 'react';
import Chart from 'chart.js';
import numeral from 'numeral';
import { ProvidersContext } from '../../contexts/ProvidersContext';

const BarWithLineChart = (props) => {
    const { data, dollars } = props;
    const transactions = [];
    const amountPaid = []

    Object.values(data.transactions).forEach(value => {
        transactions.push(value);
    });

    Object.values(data.amountPaid).forEach(value => {
        amountPaid.push(value);
    });

    const labelsForLineAndBarChart = [
        "<= 30 d",
        "50 d",
        "90 d",
        "more"
    ];

    const graphCanvas = useRef();

    const { npi } = useContext(ProvidersContext);

    useEffect(() => {

        Chart.defaults.global.elements.line.tension = 0;
        new Chart(graphCanvas.current, {
            type: 'bar',
            data: {
                labels: labelsForLineAndBarChart,
                datasets: [
                    {
                        scaleShowLabels: false,
                        type: 'line',
                        label: "Transactions",
                        data: transactions,
                        backgroundColor: 'rgba(0,0,0,0)',
                        pointBackgroundColor: 'rgba(54,162,235,1)',
                        pointBorderColor: 'rgba(54,162,235,1)',
                        borderColor: 'rgba(54,162,235,1)',
                        yAxisID: 'transactions',
                    },
                    {
                        label: "Amount Paid",
                        pointBorderColor: ' rgba(255,205,86,1)',
                        data: amountPaid,
                        borderColor: 'rgba(255,205,86,1)',
                        backgroundColor: 'rgba(255,205,86,1)',
                        yAxisID: 'amountPaid',
                    }
                ]
            },
            options: {
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            // data for manipulation
                            if (tooltipItem.datasetIndex === 0) {
                                return numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0');
                            } else return '$' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0.00');
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: "amountPaid",
                            ticks: {
                                beginAtZero: true,
                                callback: function (label) {
                                    if (label < 1000) {
                                        return label;
                                    } else {
                                        return label / 1000 + 'k';
                                    }
                                }
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Amount Paid'
                            }
                        },
                        {
                            id: "transactions",
                            lineTension: 0,
                            position: 'right',
                            ticks: {
                                beginAtZero: true,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Transactions'
                            }
                        },
                    ]
                },
            }
        });

    }, [npi]);

    return (
        <div>
            <canvas ref={graphCanvas} />
        </div>
    );
}

export default BarWithLineChart;