import React, { useState } from 'react';
import graphQL from '../../apis/graphQL';

const ConnectionRow = (props) => {

    const { rol, status, firstName, lastName, email, _id, npi } = props;
    // loading
    const [loading, setLoading] = useState(false);
    // rowStatus
    const [rowStatus, setRowStatus] = useState(status);
    const grantOrRevokeOrDelete = async (_id, access, deleteAction = false) => {
        setLoading(true);
        const response = await graphQL(`mutation{
            grantOrRevokeAccessToProvider(npi: ${npi}, authorizedRepresentativeId: "${_id}",access:${access},deleteAction:${deleteAction}){
                success
                message
                }
            }`);
        //setLoading(false);
        if (response.grantOrRevokeAccessToProvider) {
            if (response.grantOrRevokeAccessToProvider.message !== 'Deleted') {
                setRowStatus(!rowStatus);
                setLoading(false);
            }
        } else if (response) {
            setLoading(false);
            // setGraphQLErrors(response);
        }
    }

    return (
        <tr className="clickable-row">
            <td>
                <div className="user-info">
                    <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{`${firstName} ${lastName}`}</strong><br />
                    {email}
                </div>
            </td>
            <td>{rol || 'rol'}</td>
            <td>{rowStatus ? 'Approved' : 'Access Requested'}</td>
            <td>
                {rol !== 'Authorized Representative' ? <span></span> :
                    loading ? <div className="loader"></div> : rowStatus ?
                        <span><a onClick={() => { grantOrRevokeOrDelete(_id, false) }}
                            className="btn btn-secondary"
                        >Revoke</a><a onClick={() => { grantOrRevokeOrDelete(_id, false, true) }} className="btn btn-link">Delete</a></span> :
                        <span>
                            <a className="btn btn-primary" onClick={() => { grantOrRevokeOrDelete(_id, true) }}>Grant</a>
                            <a onClick={() => { grantOrRevokeOrDelete(_id, false, true) }} className="btn btn-link">Delete</a>
                        </span>
                }
            </td>
            <td></td>

        </tr>

    );
}

export default ConnectionRow;