import React, { useState, useEffect } from 'react';
import Input from '../../reusable/Input';
import graphQL from '../../../apis/graphQL';
import AuthContextProvider from '../../../contexts/AuthContext';

const ChangePassword = () => {

    // loading
    const [loading, setLoading] = useState(false);
    // errorsResponse
    const [errorsResponse, setErrorsResponse] = useState(false);
    // disableButton 
    const [disableButton, setDisableButton] = useState(true);
    // currentPassword
    const [currentPassword, setCurrentPassword] = useState('');
    // currentPasswordError
    const [currentPasswordError] = useState(false);
    // password
    const [password, setPassword] = useState('');
    // confirmPassword
    const [confirmPassword, setConfirmPassword] = useState('');
    // passwordError
    const [passwordError, setPasswordError] = useState(false);
    // confirmPasswordError
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    // graphQLErrors
    const [graphQLErrors] = useState([]);

    // Monitor fields and enable the continue button when all fields aren't empty
    useEffect(() => {
        if (currentPassword === '' || password === '' || confirmPassword === '' || graphQLErrors.length > 0 || passwordError.length > 0) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [currentPassword, password, confirmPassword, graphQLErrors, passwordError]);

    //Every time that password field changes, run the regex verification; if password isn't empty
    useEffect(() => {
        if (password !== '') {
            passwordRegexValidation();
        } else {
            setPasswordError(false);
        }
    }, [password]);

    const onContinueButtonClicked = async () => {
        //Check that the passwords are equal
        if (password !== confirmPassword) {
            console.log('test');
            setConfirmPasswordError('Password and Confirm Password needs to be equal');
            return;
        } else {
            setConfirmPasswordError(false);
            setPassword(password);
        }
        setLoading(true);
        const response = await graphQL(`mutation{
                updateCurrentPassword(currentPassword: "${currentPassword}", password: "${password}", confirmPassword: "${confirmPassword}"){
                success
                message
                }
            }`);
        setLoading(false);
        if (response.updateCurrentPassword) {

        } else if (response) {
            setDisableButton(false);
            setLoading(false);
            setErrorsResponse(response);
        }
    }

    const passwordRegexValidation = () => {
        let errorMessage = [];
        //Contains a number validation
        var regex = /[0-9]/g;
        if (regex.exec(password) == null) {
            errorMessage.push("Password must contain a number");
        }
        //length validation needs 8 characters mininum
        if (password.length < 8) {
            errorMessage.push("Password must at least 8 characters long");
        }

        //Contains at least one special character
        regex = /^[a-zA-Z0-9 ]*$/;
        if (regex.exec(password) != null) {
            errorMessage.push("Password must contain at least a special character");
        }
        if (errorMessage.length === 0) {
            setPasswordError(false);
        } else {
            setPasswordError(errorMessage.map((error, index) => {
                return <p key={index}>{error}</p>
            }));
        }
    }

    //onChange fields
    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'currentPassword':
                setCurrentPassword(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    }

    return (
        <AuthContextProvider>
            <div>
                <div class="container-with-sidebar__heading-bar sticky">
                    <h1 className="container-with-sidebar__title">Change Password</h1>
                </div>
                <section className="container-with-sidebar__content-block">
                    <div className="row indented gap-triple">
                        <div className="col">
                            <p className="text-center">
                                <img src="images/image-password-recovery.png"
                                    alt="Illustration depicting a lock and password" className="mt-1" />
                            </p>
                        </div>
                        <div className="col-grow-3">
                            <p className="p-primary text-bold">Please enter a new password and the new
                            password again for
						verification:</p>
                            <form action="" className="mt-3">

                                {/* current password field */}
                                <Input
                                    type="password"
                                    label="Current Password:"
                                    id="currentPassword"
                                    placeholder=""
                                    errorMessage={currentPasswordError}
                                    onChange={onChange}
                                />
                                {/* password field */}
                                <Input
                                    type="password"
                                    label="Password:"
                                    id="password"
                                    placeholder=""
                                    errorMessage={passwordError}
                                    onChange={onChange}
                                />
                                {/* confirmPassword field */}
                                <Input
                                    type="password"
                                    label="Confirm Password:"
                                    id="confirmPassword"
                                    onChange={onChange}
                                    placeholder=""
                                    errorMessage={confirmPasswordError}
                                />

                                <div className="form-group mt-3">
                                    <a href="" className="btn btn-secondary mr-2">Cancel</a>
                                    <button
                                        type="button"
                                        disabled={disableButton}
                                        className="btn btn-primary"
                                        onClick={onContinueButtonClicked}
                                    >{loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> : 'Continue'}</button>
                                </div>
                                {/* Error Message */}
                                {errorsResponse ?
                                    <span
                                        style={{ color: 'red' }}>{errorsResponse}
                                    </span> : <span></span>
                                }
                                {/* .Error Message */}
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </AuthContextProvider>);
}

export default ChangePassword;