import React, { createContext, useState, useEffect } from 'react';
import history from '../history';

export const LoggedUserContext = createContext();

const LoggedUserContextProvider = (props) => {

    // reason
    const [reason, setReason] = useState('');
    // additionalReason
    const [additionalReason, setAdditionalReason] = useState('');
    // id
    const [id, setId] = useState('');
    //email
    const [email, setEmail] = useState('');
    // profile
    const [profile, setProfile] = useState('');
    // firstTimeLogin
    const [firstTimeLogin, setFirstTimeLogin] = useState('');
    // connectedProviders
    const [connectedProviders, setConnectedProviders] = useState([]);
    // connectedUsers
    const [connectedUsers, setConnectedUsers] = useState([]);
    // firstName
    const [firstName, setFirstName] = useState('');
    // lastName
    const [lastName, setLastName] = useState('');
    // isPMG
    const [isPMG, setIsPMG] = useState(false);

    // scrollingClass
    const [scrollingClass, setScrollingClass] = useState('container-with-sidebar__title');

    console.log('connectedProviders');
    console.log(connectedProviders);

    useEffect(() => {
        window.onscroll = function () {
            if (window.pageYOffset > 500) {
                setScrollingClass('container-with-sidebar__title sticky');
            } else {
                setScrollingClass('container-with-sidebar__title');
            }
        };
    }, []);

    return (
        <LoggedUserContext.Provider value={{ email, setEmail, profile, setProfile, firstTimeLogin, setFirstTimeLogin, connectedProviders, setConnectedProviders, connectedUsers, setConnectedUsers, firstName, setFirstName, lastName, setLastName, isPMG, setIsPMG, scrollingClass, setScrollingClass, id, setId, reason, setReason, additionalReason, setAdditionalReason }}>
            {props.children}
        </LoggedUserContext.Provider>
    );
}

export default LoggedUserContextProvider;