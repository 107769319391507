import React from 'react';
import { Link } from 'react-router-dom';

const Step4 = (props) => {
    return (
        <div>
            <div className="container">
                <div className="row indented gap-double">
                    <div className="col instructions">
                        <h2>Email Verified</h2>
                        <p>
                            <img src="/images/ilus-email-sent.png" alt="Email Illustration" className="mb-2 mt-2" />
                        </p>
                    </div>
                    <div className="col-grow-3 instructions">
                        <p className="mt-2">Your email has been verified.</p>
                        <p>Sign in here to access your account.</p>
                        <p className="mt-3">
                            <Link to="/main" className="btn btn-primary">Log In</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step4;