import React, { useContext, useEffect, useState } from 'react';
import AuthContextProvider from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import graphQL from '../../../apis/graphQL';
import history from '../../../history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const AddConnection = () => {

    const { npi } = useContext(ProvidersContext);
    // disabled
    const [disabled, setDisabled] = useState(true);
    // loading
    const [loading, setLoading] = useState(false);
    // email
    const [email, setEmail] = useState('');

    const emailChange = (e) => {
        setEmail(e.target.value);
        if (validateEmail(e.target.value)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    const authorize = async () => {
        setLoading(true);
        const response = await graphQL(`mutation{
        authorizeConnection(npi: ${npi}, email: "${email}"){
            success
            message
        }
        }`);
        setLoading(false);
        if (response.authorizeConnection) {
            history.push('/addConnectionConfirmed', { email: email });
        } else if (response) {
            setLoading(false);
            //setGraphQLErrors(response);
        }
    }

    return (
        <AuthContextProvider>
            <div>
                <div className="container-with-sidebar__heading-bar sticky"><div className="row row--centered"><div className="col-grow-2"><h2><Link to="/providerConnections"><FontAwesomeIcon icon={faChevronLeft} className="fas fa-chevron-left icono-cancel" aria-hidden="true" /></Link>Add authorized user</h2></div><div className="col"><ul className="wizards-steps ml-3">
                    <li className="current"><span className="sr-only ">Step 1</span></li>
                    <li><span className="sr-only ">Step 2</span></li>
                </ul>
                </div>
                </div>
                </div>
                <div className="container-with-sidebar__content-block">
                    <div className="row indented gap-double">
                        <div className="col instructions">
                            <p>
                                <img src="images/illustration-add-user.png" alt="Search Provider" className="mb-2 mt-2" /></p>
                        </div>
                        <div className="col-grow-3">
                            <p className="text-bold mb-2 p-primary">Authorized user email:</p>
                            <div className="form-group">
                                <label htmlFor="npi">Email:</label>
                                <input type="text" placeholder="myemail@email.com" onChange={emailChange} />
                            </div>
                            <div className="form-group mt-3">
                                <Link to="/providerConnections" className="btn btn-secondary mr-2">Cancel</Link>
                                <button disabled={disabled} type="submit" className="btn btn-primary" onClick={authorize}>{loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> : 'Authorize Connection'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthContextProvider>
    );
}

export default AddConnection;