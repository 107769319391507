import React, { useContext, useEffect, useState } from 'react';
import { LoggedUserContext } from '../../../contexts/LoggedUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ProviderRow from '../../reusable/ProviderRow';
import { Link } from 'react-router-dom';
import AuthContextProvider from '../../../contexts/AuthContext';
import history from '../../../history';
import { SocketContext } from '../../../contexts/SocketContext';
import ProviderPendingRow from '../../reusable/ProviderPendingRow';
import graphQL from '../../../apis/graphQL';
import ProviderAccessRequestedRow from '../../reusable/ProviderAccessRequestedRow';


const List = () => {

    const { connectedProviders, setConnectedProviders } = useContext(LoggedUserContext);
    const { socketIO } = useContext(SocketContext);
    // loadingScreen
    const [loadingScreen, setLoadingScreen] = useState(true);
    // providersPendingToAccept
    const [providersPendingToAccept, setProvidersPendingToAccept] = useState([]);
    // providersThatRequestAccess
    const [providersThatRequestAccess, setProvidersThatRequestAccess] = useState([]);
    // updateCounter
    const [updateCounter, setUpdateCounter] = useState(0);
    useEffect(() => {
        if (socketIO) {
            socketIO.on('updateConnectedUsers', (cp) => {
                setConnectedProviders(cp);
            })
        }
    }, [socketIO]);

    useEffect(() => {
        let counter = updateCounter + 1;
        if (socketIO) {
            socketIO.on('updateAuthorizedRepresentatives', () => {
                setUpdateCounter(counter);
                console.log(counter);
            })
        }
    }, [socketIO, updateCounter]);

    useEffect(() => {
        (async () => {
            setProvidersPendingToAccept([]);
            //setLoading(true);
            const response = await graphQL(`query{
            providersPendingToAccept{
                npi
                firstName
                lastName
                authorizedRepresentatives{
                _id
                firstName
                lastName
                email
                approved
                accepted
                }
            }
            }`);
            // setLoading(false);
            if (response.providersPendingToAccept) {
                console.log('providersPendingToAccept');
                setProvidersPendingToAccept(response.providersPendingToAccept);
            } else if (response) {
                setProvidersPendingToAccept([]);
            }
        })()
    }, [updateCounter]);

    //Providers that request access
    useEffect(() => {
        (async () => {
            setProvidersThatRequestAccess([]);
            //setLoading(true);
            const response = await graphQL(`query{
            providersThatRequestAccess{
                npi
                firstName
                lastName
                authorizedRepresentatives{
                _id
                firstName
                lastName
                email
                approved
                accepted
                }
            }
            }`);
            // setLoading(false);
            if (response.providersThatRequestAccess) {
                //Set loading screen to false after receive response from endpoint
                setLoadingScreen(false);
                console.log('providersThatRequestAccess');
                setProvidersThatRequestAccess(response.providersThatRequestAccess);
            } else if (response) {
                setProvidersThatRequestAccess([]);
            }
        })()
    }, [updateCounter, connectedProviders]);


    //initially verify if connected providers length > 0. If not, then redirects to emptyList
    useEffect(() => {
        // if (connectedProviders.length === 0) {
        //     history.push('/emptyList');
        // }
    }, []);

    useEffect(() => {

        renderConnectedProviders();
    }, [connectedProviders, updateCounter]);

    const renderConnectedProviders = () => {
        return connectedProviders.map((provider) => {
            console.log('provider role');
            console.log(provider.role);
            console.log('provider npi');
            console.log(provider.npi);
            return <ProviderRow disconnect firstName={provider.firstName} lastName={provider.lastName} npi={provider.npi} key={provider.npi} viewProvider rol={provider.role === 0 ? 'Primary Representative' : 'Authorized Representative'} status={provider.status.toLowerCase()} />
        })
    }

    const renderEmpty = () => {
        return <section className="container-with-sidebar__content-block">
            <div className="row mt-5 mb-5">
                <div className="col text-right">
                    <img className="mr-2" src="/images/ilustracion-empty-providers.png" alt="Icons depicting a user provider and some medichal buildings" />
                </div>
                <div className="col-grow-2">
                    <h1>
                        You don’t have any connected providers.
                    </h1>
                    <p className="p-primary">
                        <Link to="/selectProviderType">Start by adding one.</Link>
                    </p>
                </div>
            </div>
        </section>
    }

    const renderProvidersPendingToAccept = () => {
        return providersPendingToAccept.map((provider) => {
            return <ProviderPendingRow firstName={provider.firstName} lastName={provider.lastName} npi={provider.npi} key={provider._id} />
        })
    }
    const renderProvidersThatRequestAccess = () => {
        return providersThatRequestAccess.map((provider) => {
            return <ProviderAccessRequestedRow firstName={provider.firstName} lastName={provider.lastName} npi={provider.npi} key={provider.npi} />
        })
    }

    return (
        <AuthContextProvider>
            <div>
                <div class="container-with-sidebar__heading-bar sticky">
                    <div className="row row--centered">
                        <div className="col">
                            <h1 className="container-with-sidebar__title">Connected Providers <small className="isnumber">({connectedProviders === null ? 0 : connectedProviders.length})</small></h1>
                        </div>
                        <div className="col text-right">
                            <Link to="/selectProviderType" className="btn-link-fancy small-text">
                                Add Providers&nbsp;
                <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{ color: 'rgb(55,136,176)' }}></FontAwesomeIcon>
                            </Link>
                        </div>
                    </div>
                </div>


                {connectedProviders.length > 0 || providersThatRequestAccess.length > 0 || providersPendingToAccept.length > 0 ?
                    <section className="container-with-sidebar__content-block">
                        <div className="panel panel-data mt-1">
                            <div className="panel-body">
                                <div className="table-scroll">
                                    <table className="providers-list">
                                        <tbody>
                                            <tr>
                                                <th>Provider</th>
                                                <th>Rol</th>
                                                <th>Status</th>
                                                <th>Quick Actions</th>
                                                <th></th>
                                            </tr>
                                            {renderConnectedProviders()}
                                            {renderProvidersPendingToAccept()}
                                            {renderProvidersThatRequestAccess()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div >
                    </section>
                    : loadingScreen ?
                        <div class="loading" style={{ marginTop: '150px' }}><div class="loader-screen"></div></div> :
                        <section className="container-with-sidebar__content-block">
                            <div className="row mt-5 mb-5">
                                <div className="col text-right">
                                    <img className="mr-2" src="/images/ilustracion-empty-providers.png" alt="Icons depicting a user provider and some medichal buildings" />
                                </div>
                                <div className="col-grow-2">
                                    <h1>
                                        You don’t have any connected providers.
                                </h1>
                                    <p className="p-primary">
                                        <Link to="/selectProviderType">Start by adding one.</Link>
                                    </p>
                                </div>
                            </div>
                        </section>

                }

            </div>
        </AuthContextProvider >
    );
}

export default List;