import React, { useContext, useEffect, useState } from 'react';
import Input from '../../reusable/Input';
import Select from '../../reusable/Select';
import { LoggedUserContext } from '../../../contexts/LoggedUser';
import graphQL from '../../../apis/graphQL';
import history from '../../../history';
import AuthContextProvider from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';

const options = [
    { value: "", label: "Select" },
    { value: "Jr.", label: "Jr." },
    { value: "Sr.", label: "Sr." },
    { value: "Sra.", label: "Sra." },
    { value: "CPA.", label: "CPA." },
    { value: "M.D.", label: "M.D." },
    { value: "PhD.", label: "PhD." }
]
const country = [
    { value: 'USA', label: 'USA' }
]
const state = [
    { value: 'PR', label: 'PR' }
]

const getProfileText = (value) => {
    let res = '';
    switch (value) {
        case '1':
            res = 'I\'am a provider'
            break;
        case '2':
            res = 'I represent a provider'
            break;
        case '3':
            res = 'Both'
            break;
        default:
            break;
    }
    return res;
}

//For check if the field does blur from input field action
let fieldDoesBlur = false;
let onSelectFieldChange = false;
let onDatePickerChange = false;

const CompletePersonalInformation = () => {

    const { profile, firstTimeLogin, setFirstName, setLastName, setFirstTimeLogin } = useContext(LoggedUserContext);
    // formState
    const [formState, setFormState] = useState({ values: { middleName: '', homeAddressLine2: '' } });
    // disableButton
    const [disableButton, setDisableButton] = useState(true);
    // loading
    const [loading, setLoading] = useState(false);

    //If firstTimeLogin === false, then prohibits enter the page
    useEffect(() => {
        window.scrollTo(0, 0);
        if (firstTimeLogin === false) {
            history.push('/');
        }
        return;
    }, [firstTimeLogin]);

    //Check all required fields and change the disableButton for the continue button
    useEffect(() => {
        const { firstName, lastName, country, state, dateOfBirth, ssnLast4Digits, email, personalPhoneNumber, homeAddressLine1, city, zipCode } = formState.values;

        if (firstName === '' || firstName === undefined || lastName === '' || lastName === undefined || country === '' || country === undefined || state === '' || state === undefined || dateOfBirth === '' || dateOfBirth === undefined || dateOfBirth === null || dateOfBirth === "null" || ssnLast4Digits === '' || ssnLast4Digits === undefined || email === '' || email === undefined || personalPhoneNumber === '' || personalPhoneNumber === undefined || homeAddressLine1 === '' || homeAddressLine1 === undefined || city === '' || city === undefined || zipCode === '' || zipCode === undefined) {
            setDisableButton(true)
        } else {
            setDisableButton(false);
        }
    }, [formState.values]);

    useEffect(() => {
        //To prevent unmounted error. First checks if firstTimeLogin is true
        if (firstTimeLogin) {
            (async () => {
                try {
                    const response = await graphQL(`query{
                    getCompleteInformation{
                        status
                        firstName
                        middleName
                        lastName
                        suffix
                        country
                        state
                        dateOfBirth
                        ssnLast4Digits
                        email
                        personalPhoneNumber
                        homeAddressLine1
                        homeAddressLine2
                        city
                        zipCode
                    }
                    }`);
                    if (response.getCompleteInformation) {
                        console.log(response.getCompleteInformation);
                        setFormState(formState => ({
                            ...formState,
                            values: response.getCompleteInformation
                        }));
                    }
                } catch (error) {
                    //when trying to get draft information and draft isn't exists you get an error, but for our purposes we only need to bypass the error. The error occurs because initially there not existing draft object for the form
                }
            })()
        }
        //Needs to check for updates on firstTimeLogin that cames from context
    }, [firstTimeLogin]);

    useEffect(() => {

        //check if the action comes from field blur action
        if (fieldDoesBlur || onSelectFieldChange || onDatePickerChange) {
            (async () => {
                await graphQL(`mutation{
                    addPersonalInformation(personalInformation: {
                        draft:true
                        firstName:"${formState.values.firstName || ""}"
                        middleName:"${formState.values.middleName || ""}"
                        lastName:"${formState.values.lastName || ""}"
                        suffix:"${formState.values.suffix || ""}"
                        country:"${formState.values.country || ""}"
                        state:"${formState.values.state || ""}"
                        dateOfBirth:"${formState.values.dateOfBirth || ""}"
                        ssnLast4Digits:"${formState.values.ssnLast4Digits || ""}"
                        email:"${formState.values.email || ""}"
                        personalPhoneNumber:"${formState.values.personalPhoneNumber || ""}"
                        homeAddressLine1:"${formState.values.homeAddressLine1 || ""}"
                        homeAddressLine2:"${formState.values.homeAddressLine2 || ""}"
                        city:"${formState.values.city || ""}"
                        zipCode:"${formState.values.zipCode || ""}"
                    }){
                        success
                        message
                    }
                    }`);
            })()
            fieldDoesBlur = false;
            onSelectFieldChange = false;
            onDatePickerChange = false;
        }

    }, [formState.values]);

    const updateDraft = async (e) => {
        //fieldDoesBlur is used to block calling to API when field don't blur
        fieldDoesBlur = true;
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;
        const checked = e.target.checked
        //Change state
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    }

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;
        const checked = e.target.checked
        //Change state
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    }

    const onChangeSelectInput = async (name, selectedOption) => {
        const value = selectedOption.value
        onSelectFieldChange = true;
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [name]: value
            }
        }));
    }

    const datePickerChange = (date) => {
        const name = "dateOfBirth";
        const value = date
        onDatePickerChange = true;
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [name]: value
            }
        }));
    }

    const continueButtonClicked = async () => {
        if (formState.values.termsAgree !== true) {
            setFormState(formState => ({
                ...formState,
                values: {
                    ...formState.values,
                    termsAgree: false
                }
            }));
            return;
        } else {
            setLoading(true);
            const response = await graphQL(`mutation{
                addPersonalInformation(personalInformation: {
                    draft: false
                    firstName:"${formState.values.firstName}"
                    middleName:"${formState.values.middleName}"
                    lastName:"${formState.values.lastName}"
                    suffix:"${formState.values.suffix}"
                    country:"${formState.values.country}"
                    state:"${formState.values.state}"
                    dateOfBirth:"${formState.values.dateOfBirth}"
                    ssnLast4Digits:"${formState.values.ssnLast4Digits}"
                    email:"${formState.values.email}"
                    personalPhoneNumber:"${formState.values.personalPhoneNumber}"
                    homeAddressLine1:"${formState.values.homeAddressLine1}"
                    homeAddressLine2:"${formState.values.homeAddressLine2}"
                    city:"${formState.values.city}"
                    zipCode:"${formState.values.zipCode}"
                }){
                    success
                    message
                }
                }`);
            setLoading(false);
            if (response.addPersonalInformation) {
                setFirstName(formState.values.firstName);
                setLastName(formState.values.lastName);
                history.push('/emptyList');
                //needs to be after history.push. useEffect overwrites data if done previously of history push.
                setFirstTimeLogin(false);
            }
        }
    }

    return (
        <AuthContextProvider>
            <div>
                {firstTimeLogin ?
                    <div>
                        {/* <!-- Menu Principal --> */}

                        <div className="container-with-sidebar__heading-bar sticky">
                            <h1 className="container-with-sidebar__title">Complete your personal information</h1>
                        </div>
                        <section className="container-with-sidebar__content-block">
                            <div className="row indented gap-triple">
                                <div className="col instructions">
                                    <p><img src="images/illustration-personal-information.png" alt="Illustration personal information"
                                        className="mb-2 mt-2" /></p>
                                </div>
                                <div className="col-grow-3">
                                    <p>Please provide the information requested. </p>
                                    <form>
                                        <p className="text-bold mb-2">Personal Information</p>
                                        <Input
                                            type="text"
                                            label="Profile:"
                                            id="profile"
                                            placeholder={getProfileText(profile)}
                                            disabled
                                        />
                                        <Input
                                            type="text"
                                            label="First Name:"
                                            id="firstName"
                                            placeholder="Ex. John"
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.firstName || ''}
                                        />
                                        <Input
                                            type="text"
                                            label="Middle Name:"
                                            id="middleName"
                                            placeholder="Ex. Eduard"
                                            optional
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.middleName || ''}
                                        />
                                        <Input
                                            type="text"
                                            label="Last Name:"
                                            id="lastName"
                                            placeholder="Ex. Doe"
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.lastName || ''}
                                        />
                                        <Select
                                            label="Suffix:"
                                            id="suffix"
                                            options={options}
                                            customClass="input-w100"
                                            optional
                                            onChange={(selectedOption) => { onChangeSelectInput("suffix", selectedOption) }}
                                            value={options.filter((option) => {
                                                return formState.values.suffix === option.value;
                                            })}
                                        />
                                        <Input
                                            type="date"
                                            label="Date of Birth:"
                                            id="dateOfBirth"
                                            onChange={datePickerChange}
                                            value={(formState.values.dateOfBirth && formState.values.dateOfBirth !== "null") ? new Date(formState.values.dateOfBirth) : null}
                                        />
                                        <Input
                                            type="text"
                                            label="SSN last 4 digits:"
                                            id="ssnLast4Digits"
                                            placeholder="Ex. 1234"
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.ssnLast4Digits || ''}
                                        />
                                        <p className="text-bold mb-2 mt-3">Contact Information</p>
                                        <Input
                                            type="text"
                                            label="Email:"
                                            id="email"
                                            placeholder="Ex. john@example.com"
                                            customClass="input-w400"
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.email || ''}
                                        />
                                        <Input
                                            type="text"
                                            label="Personal phone number:"
                                            id="personalPhoneNumber"
                                            placeholder="Ex. (544) 123-4567"
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.personalPhoneNumber || ''}
                                        />
                                        <Input
                                            type="text"
                                            label="Home address line1:"
                                            id="homeAddressLine1"
                                            placeholder="Street and number"
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.homeAddressLine1 || ''}
                                        />
                                        <Input
                                            type="text"
                                            label="Home address line2:"
                                            id="homeAddressLine2"
                                            placeholder="Aparment, suite, unit, etc."
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            optional
                                            value={formState.values.homeAddressLine2 || ''}
                                        />
                                        <Input
                                            type="text"
                                            label="City:"
                                            id="city"
                                            placeholder="Ex. San Juan"
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.city || ''}
                                        />
                                        <Select
                                            label="Country:"
                                            id="country"
                                            options={country}
                                            customClass="input-w100"
                                            onChange={(selectedOption) => { onChangeSelectInput("country", selectedOption) }}
                                            value={country.filter((option) => {
                                                return formState.values.country === option.value;
                                            })}
                                        />
                                        <Select
                                            label="State:"
                                            id="state"
                                            options={state}
                                            customClass="input-w100"
                                            onChange={(selectedOption) => { onChangeSelectInput("state", selectedOption) }}
                                            value={state.filter((option) => {
                                                return formState.values.state === option.value;
                                            })}
                                        />
                                        <Input
                                            type="text"
                                            label="Postal / Zip Code:"
                                            id="zipCode"
                                            placeholder="Ex. 00983"
                                            onBlur={updateDraft}
                                            onChange={onChange}
                                            value={formState.values.zipCode || ''}
                                        />
                                        <div className="form-group mt-4">
                                            <input type="checkbox" name="termsAgree" onChange={onChange} />
                                            <span className="note-aside">I certify that the information entered here is valid.</span>
                                            {formState.values.termsAgree || formState.values.termsAgree === undefined ? <div></div> :
                                                <div
                                                    className="missing-field-message"
                                                >Certify that information is correct.</div>
                                            }
                                        </div>
                                        <div className="form-group mt-3">
                                            <Link to="/main" className="btn btn-secondary mr-2">Cancel</Link>
                                            <button
                                                onClick={continueButtonClicked}
                                                type="button"
                                                className="btn btn-primary"
                                                disabled={disableButton}
                                            >{loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> : 'Continue'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                    : <div></div>}
            </div >
        </AuthContextProvider>
    );
}

export default CompletePersonalInformation;