import React, { useState, useContext, useEffect } from 'react';
import history from '../../../history';
import AuthContextProvider from '../../../contexts/AuthContext';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import graphQL from '../../../apis/graphQL';

const ProviderDisclosureFromRequest = () => {

    // acceptTerms
    const [acceptTerms, setAcceptTerms] = useState(true);
    // loading
    const [loading, setLoading] = useState(false);

    const { npi: npiFromContext, npiThatNeedsAuthorization } = useContext(ProvidersContext);

    useEffect(() => {
        console.log(npiFromContext);
        if (npiFromContext === '') {
            history.push('/selectProviderType');
        }
    }, [npiFromContext]);

    const sendRequest = async () => {
        setLoading(true);
        const response = await graphQL(`mutation{
                requestAccessToProvider(npi: ${npiThatNeedsAuthorization}){
                success
                message
                }
            }`);
        setLoading(false);
        if (response.requestAccessToProvider) {
            history.push('/providerRequestConfirmed');
        } else if (response) {
            setLoading(false);
            //    setGraphQLErrors(response);
        }
    }

    return (
        <AuthContextProvider>
            <div>
                <div className="row indented gap-double row--centered">
                    <div className="col"><h2>Disclosure</h2></div>
                    <div className="col-grow-3">
                        <ul className="wizards-steps ml-3">
                            <li><span className="sr-only ">Step 1</span></li>
                            <li><span className="sr-only ">Step 2</span></li>
                            <li className="current"><span className="sr-only ">Step 3</span></li>
                            <li><span className="sr-only ">Step 4</span></li>
                        </ul>
                    </div>
                </div>

                {/* <!-- Contenido --> */}
                <div className="container-with-sidebar__content-block">
                    <div className="row indented gap-double">
                        <div className="col instructions">

                            <p>
                                <img src="images/image-disclosure.png" alt="Search Provider" className="mb-2 mt-2" /></p>
                        </div>
                        <div className="col-grow-3">
                            <p className="text-bold mb-2 p-primary">Legal disclosure for Authorized Representative.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse pellentesque nunc vel diam
                            placerat, quis vestibulum erat sagittis. Curabitur non ipsum magna. Ut at dictum metus, in aliquet
                            tellus. Vivamus dictum risus at porta vestibulum. Morbi nec purus arcu. Donec finibus eros non augue
                            lobortis, ut porttitor arcu vestibulum. Aenean vestibulum, dui eget rutrum porttitor, felis ipsum
                            tristique arcu, ut accumsan diam augue id augue. Nunc et maximus metus. Donec eu lectus id justo
                    fringilla maximus ut vel ex.</p>
                            <form action="add-provider-request-confirmed.html#contentStart">
                                <div className="form-group mt-2">
                                    <input type="checkbox" name="termsagree" value="0" onChange={() => { setAcceptTerms(!acceptTerms) }} />
                                    <span className="note-aside">I confirm that I am authorized as stated.</span>
                                </div>
                                <div className="form-group mt-3">
                                    <a href="add-provider-search.html" className="btn btn-secondary mr-2">Cancel</a>
                                    <button type="button" className="btn btn-primary"
                                        disabled={acceptTerms}
                                        onClick={sendRequest}
                                    >{!loading ? 'Continue' : <div className="loader-linear--bg-white"><span></span><span></span><span></span></div>}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </AuthContextProvider>
    );
}

export default ProviderDisclosureFromRequest;