import React, { useRef, useEffect, useContext, useState } from 'react';
import Chart from 'chart.js';
import numeral from 'numeral';
import { ProvidersContext } from '../../contexts/ProvidersContext';

const LineChartFilledTwoDatasets = (props) => {
    const { data, dollars } = props;
    const labels = [];
    const encounters = [];
    const graphCanvas = useRef();
    // table
    const [table, setTable] = useState('');

    let year = parseInt(data[0].date.year);
    let month = parseInt(data[0].date.month);
    const { npi } = useContext(ProvidersContext);

    useEffect(() => {
        let x = 1
        do {
            if (month === 0) {
                month = 12;
                year--;
            }
            let monthName = '';
            switch (month) {
                case 1:
                    monthName = 'JAN'
                    break;
                case 2:
                    monthName = 'FEB'
                    break;
                case 3:
                    monthName = 'MAR'
                    break;
                case 4:
                    monthName = 'APR'
                    break;
                case 5:
                    monthName = 'MAY'
                    break;
                case 6:
                    monthName = 'JUN'
                    break;
                case 7:
                    monthName = 'JUL'
                    break;
                case 8:
                    monthName = 'AUG'
                    break;
                case 9:
                    monthName = 'SEP'
                    break;
                case 10:
                    monthName = 'OCT'
                    break;
                case 11:
                    monthName = 'NOV'
                    break;
                case 12:
                    monthName = 'DEC'
                    break;

                default:
                    break;
            }
            labels.push(`${monthName}-${year}`);
            const res = data.filter((data) => {
                return parseInt(data.date.month) === month && parseInt(data.date.year) === year;
            });
            if (res.length > 0) {
                encounters.push(res[0].encounters);
            } else {
                encounters.push(0);
            }

            month--;
            x++;
        } while (x <= 12)


        let encountersTotal = 0;


        encounters.map((value) => {
            encountersTotal += value;
        });


        if (props.showTable) {
            let ta = labels.map((label, x) => {
                if (x === labels.length - 1) {
                    return (
                        <React.Fragment key={x}>
                            <tr >
                                <td>{label}</td>
                                <td className="text-right">{numeral(encounters[x]).format('0,0')}</td>

                            </tr>
                            <tr className="text-bold">
                                <td>Total</td>
                                <td className="text-right">{numeral(encountersTotal).format('0,0')}</td>
                            </tr>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <tr key={x}>
                            <td>{label}</td>
                            <td className="text-right">{numeral(encounters[x]).format('0,0')}</td>
                        </tr>
                    )
                }
            });
            setTable(ta);
        } else {
            Chart.defaults.global.elements.line.tension = 0;
            new Chart(graphCanvas.current, {
                type: 'line',
                data: {
                    labels: labels.reverse(),
                    datasets: [
                        {
                            label: "Encounter Transactions",
                            data: encounters.reverse(),
                            borderColor: 'rgba(74,192,192)',
                            pointBackgroundColor: 'rgba(74,192,192)',
                            backgroundColor: 'rgba(74,192,192,0)',
                            fill: false,
                            yAxisID: 'encounters',
                        },
                    ]
                },
                options: {
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                // data for manipulation
                                return numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0');
                            },
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                id: "encounters",
                                ticks: {
                                    beginAtZero: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: ''
                                }
                            }
                        ]
                    },
                }
            });
        }

    }, [npi]);

    if (props.showTable) {
        if (table === '') {
            return <tr></tr>
        }
        return <React.Fragment>{table}</React.Fragment>;
    } else {
        return (
            <div>
                <canvas ref={graphCanvas} />
            </div>
        );
    }
}

export default LineChartFilledTwoDatasets;