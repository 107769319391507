import React, { useEffect, useState } from 'react';
import Select from '../../reusable/Select';
import Input from '../../reusable/Input';
import graphQL from '../../../apis/graphQL';
import AuthContextProvider from '../../../contexts/AuthContext';

const SecurityQuestions = () => {

	// question1
	const [question1, setQuestion1] = useState('');
	// question2
	const [question2, setQuestion2] = useState('');
	// question3
	const [question3, setQuestion3] = useState('');
	// question4
	const [question4, setQuestion4] = useState('');
	// question5
	const [question5, setQuestion5] = useState('');
	// answers
	const [answersForQuestions, setAnswersForQuestions] = useState({ answers: {} });
	// questions1
	const [questions1, setQuestions1] = useState([]);
	// questions2
	const [questions2, setQuestions2] = useState([]);
	// questions3
	const [questions3, setQuestions3] = useState([]);
	// questions4
	const [questions4, setQuestions4] = useState([]);
	// questions5
	const [questions5, setQuestions5] = useState([]);
	// disableButton
	const [disableButton, setDisableButton] = useState(true);
	// loading
	const [loading, setLoading] = useState(false);


	//Get Questions from API and set to the corresponding fields
	useEffect(() => {
		//get questions list from api
		(async () => {
			const response = await graphQL(`query{
                getQuestions{
                    _id
                    description
                }
            }`);
			if (response.getQuestions) {
				let questionsArray1 = [];
				let questionsArray2 = [];
				let questionsArray3 = [];
				let questionsArray4 = [];
				let questionsArray5 = [];
				response.getQuestions.forEach((question, index) => {
					if (index <= 2) {
						questionsArray1.push({
							value: question._id,
							label: question.description
						});
					} else if (index <= 5) {
						questionsArray2.push({
							value: question._id,
							label: question.description
						});;
					} else if (index <= 8) {
						questionsArray3.push({
							value: question._id,
							label: question.description
						});
					} else if (index <= 11) {
						questionsArray4.push({
							value: question._id,
							label: question.description
						});
					} else {
						questionsArray5.push({
							value: question._id,
							label: question.description
						});
					}
				});
				setQuestions1(questionsArray1);
				setQuestions2(questionsArray2);
				setQuestions3(questionsArray3);
				setQuestions4(questionsArray4);
				setQuestions5(questionsArray5);
			}
		})()
	}, [])

	//verify if all questions fields are completed
	useEffect(() => {
		const { answer1, answer2, answer3, answer4, answer5 } = answersForQuestions.answers;
		if (answer1 === ''
			|| answer2 === ''
			|| answer3 === ''
			|| answer4 === ''
			|| answer5 === ''
			|| answer1 === undefined
			|| answer2 === undefined
			|| answer3 === undefined
			|| answer4 === undefined
			|| answer5 === undefined) {
			setDisableButton(true);
		} else {
			setDisableButton(false);
		}
	}, [answersForQuestions.answers])

	const question1Changed = (question) => {
		setQuestion1(question.value);
	}
	const question2Changed = (question) => {
		setQuestion2(question.value);
	}
	const question3Changed = (question) => {
		setQuestion3(question.value);
	}
	const question4Changed = (question) => {
		setQuestion4(question.value);
	}
	const question5Changed = (question) => {
		setQuestion5(question.value);
	}

	const onChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		const type = e.target.type;
		const checked = e.target.checked
		//Change state
		setAnswersForQuestions(answersForQuestions => ({
			...answersForQuestions,
			answers: {
				...answersForQuestions.answers,
				[name]: type === 'checkbox' ? checked : value
			}
		}));
	}

	const continueButtonClicked = async () => {
		setLoading(true);
		const { answer1, answer2, answer3, answer4, answer5 } = answersForQuestions.answers;
		const response = await graphQL(`mutation{
				updateSecurityQuestions(questions: [
					{
						questionId: "${question1}",
						answer: "${answer1}"
					},
					{
						questionId: "${question2}",
						answer: "${answer2}"
					},
					{
						questionId: "${question3}",
						answer: "${answer3}"
					},
					{
						questionId: "${question4}",
						answer: "${answer4}"
					},
					{
						questionId: "${question5}",
						answer: "${answer5}"
					}
				]){
				success
				message
				}
			}`);
		//setLoading(false);
		if (response.updateSecurityQuestions) {
			setLoading(false);
		} else if (response) {
			console.log(response);
			setLoading(false);
			// setGraphQLErrors(response);
		}
	}

	return (
		<AuthContextProvider>
			<div>
				<div class="container-with-sidebar__heading-bar sticky">
					<h1 className="container-with-sidebar__title">Security Questions</h1>
				</div>
				<section className="container-with-sidebar__content-block">
					<div className="row indented gap-triple">
						<div className="col instructions">
							<p><img src="images/ilus-security-questions.png"
								alt="Ilustration Security Questions" className="mb-2 mt-2" />
							</p>
						</div>
						<div className="col-grow-3">
							<form action="sign-in-step3.html">
								<p className="text-bold mb-2">Questions</p>
								{/* Question1 */}
								<Select
									label="Question 1:"
									id="question1"
									options={questions1}
									// errorMessage={value.errorMessage}
									customClass="input-w400"
									onChange={question1Changed}
								/>
								<Input
									type="text"
									label="Answer 1:"
									id="answer1"
									placeholder=""
									disabled={question1 === '' ? true : false}
									// errorMessage={value.errorMessage}
									onChange={onChange}
									customClass="input-w400"
								/>
								{/* .Question1 */}
								{/* Question2 */}
								<div className="form-zebra mb-2">
									<Select
										label="Question 2:"
										id="question2"
										options={questions2}
										customClass="input-w400"
										onChange={question2Changed}
									/>
									<Input
										type="text"
										label="Answer 2:"
										id="answer2"
										placeholder=""
										disabled={question2 === '' ? true : false}
										customClass="input-w400"
										onChange={onChange}
									/>
								</div>
								{/* .Question2 */}
								{/* Question3 */}
								<Select
									label="Question 3:"
									id="question3"
									options={questions3}
									onChange={question3Changed}
									customClass="input-w400"
								/>
								<Input
									type="text"
									label="Answer 3:"
									id="answer3"
									placeholder=""
									disabled={question3 === '' ? true : false}
									customClass="input-w400"
									onChange={onChange}
								/>
								{/* .Question3 */}
								{/* Question4 */}
								<div className="form-zebra mb-2">
									<Select
										label="Question 4:"
										id="question4"
										options={questions4}
										onChange={question4Changed}
										customClass="input-w400"
									/>
									<Input
										type="text"
										label="Answer 4:"
										id="answer4"
										placeholder=""
										disabled={question4 === '' ? true : false}
										customClass="input-w400"
										onChange={onChange}
									/>
								</div>
								{/* .Question4 */}
								{/* Question5 */}
								<Select
									label="Question 5:"
									id="question5"
									options={questions5}
									onChange={question5Changed}
									customClass="input-w400"
								/>
								<Input
									type="text"
									label="Answer 5:"
									id="answer5"
									placeholder=""
									disabled={question5 === '' ? true : false}
									customClass="input-w400"
									onChange={onChange}
								/>
								{/* .Question5 */}
								{/* <!-- Action Buttons --> */}
								<div className="form-group mt-3">
									{/* <Link to={`/securityQuestions`} className="btn btn-secondary mr-2">Cancel</Link> */}
									{/* if loading show loader  */}
									{loading ?
										<div className="loader-linear--bg-white"><span></span><span></span><span></span></div>
										:
										<button
											type="button"
											className="btn btn-primary"
											disabled={disableButton}
											onClick={continueButtonClicked}
										>Update Questions</button>
									}

								</div>
							</form>
						</div>

					</div>
				</section>
			</div >
		</AuthContextProvider>
	);
}

export default SecurityQuestions;