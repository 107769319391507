import React, { useState } from 'react';
import Input from '../../reusable/Input';
import graphQL from '../../../apis/graphQL';
import { Link } from 'react-router-dom';
import history from '../../../history';

const RegisterRequestEmailVerification = (props) => {

    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState(false);
    // loading
    const [loading, setLoading] = useState(false);
    // disable
    const [disable, setDisable] = useState(true);
    // email
    const [email, setEmail] = useState('');

    //onBlur email verifies if the email is valid and if the email exists in the database
    const onBlur = async (e) => {
        // Reset Errors
        setGraphQLErrors(false);
        setLoading(true);
        const response = await graphQL(`query{
            verifyIfUserIsAlreadyRegistered(email: "${e.target.value}"){
                success
                message
            }
        }`);
        setLoading(false);
        if (response.verifyIfUserIsAlreadyRegistered) {
            setDisable(false);
        } else if (response) {
            setDisable(true);
            setGraphQLErrors(response);
        }
    }

    const onChange = (e) => {
        setEmail(e.target.value);
    }

    const continueButtonClicked = async () => {
        setLoading(true);
        const response = await graphQL(`mutation{
            resendRegistrationEmail(email: "${email}"){
                success
                message
            }
            }`);
        setLoading(false);
        if (response.resendRegistrationEmail) {
            history.push('/resendEmailRequestSent')
        } else if (response) {
            setLoading(false);
            setGraphQLErrors(response);
        }
    }

    return (
        <div>
            <div className="login-wrapper mt-3">
                <div className="container">
                    <div className="panel-clear">
                        <div className="row">
                            <div className="col">
                                <p className="text-center">
                                    <img src="/images/ilus-email-alert.png" alt="Link illustration" className="mt-1" />
                                </p>
                            </div>
                            <div className="col-grow-2">
                                <div className="mt-3">
                                    <h2>Resend verification email</h2>
                                </div>
                                <p className="p-primary">Please use the email with which you registered on our site.</p>
                                <form>
                                    {/* email field*/}
                                    <Input
                                        type="email"
                                        label="Email:"
                                        id="email"
                                        placeholder="Ex. john@email.com"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errorMessage={graphQLErrors}
                                        mt2
                                    />
                                    {/* .email field */}
                                    <div className="form-group mt-3">
                                        <Link to="/main" className="btn btn-secondary mr-2">Cancel</Link>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={disable}
                                            onClick={continueButtonClicked}
                                        >{loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> : 'Continue'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default RegisterRequestEmailVerification;