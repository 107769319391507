import React, { useContext, useEffect } from 'react';
import AuthContextProvider from '../../../contexts/AuthContext';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import history from '../../../history';

const RequestAccessToProvider = () => {

    const { npi: npiFromContext, npiThatNeedsAuthorization,npiNameThatNeedsAuthorization,npiPrimaryRepresentative } = useContext(ProvidersContext);

    useEffect(() => {
        console.log(npiFromContext);
        if (npiFromContext === '') {
            history.push('/selectProviderType');
        }
    }, [npiFromContext]);

    const requestAccessClicked = () => {
        history.push('/providerDisclosureFromRequest');
    }

    return (
        <AuthContextProvider>
            <div>
                <div className="row indented gap-double row--centered">
                    <div className="col"><h2>Request access to provider</h2></div>
                    <div className="col-grow-3">
                        <ul className="wizards-steps ml-3">
                            <li><span className="sr-only ">Step 1</span></li>
                            <li className="current"><span className="sr-only ">Step 2</span></li>
                            <li><span className="sr-only ">Step 3</span></li>
                            <li><span className="sr-only ">Step 4</span></li>
                        </ul>
                    </div>
                </div>

                <div className="container-with-sidebar__content-block">
                    <div className="row indented gap-triple">
                        <div className="col instructions">

                            <p>The requested provider has an Authorized Officer managing user access. You can place a request to
                    access this provider.</p>
                            <p><img src="images/image-request-access.png" alt="Search Provider" className="mb-2 mt-2" /></p>
                        </div>
                        <div className="col-grow-3">
                            <p className="text-bold mb-2">Provider Information</p>
                            <form action="add-provider-disclosure-from-request.html#contentStart">
                                <div className="form-group mt-3">
                                    <label htmlFor="npi">NPI:</label>
                                    <input type="text" id="NPI" readOnly value={npiThatNeedsAuthorization} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" id="name" readOnly value={npiNameThatNeedsAuthorization} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="authOffic">Authorized Officer</label>
                                    <input type="text" id="authOffic" readOnly value={npiPrimaryRepresentative.toUpperCase()} />
                                </div>
                                <div className="form-group mt-3">
                                    <a href="add-provider-search.html#contentStart" className="btn btn-secondary mr-2">Cancel</a>
                                    <button type="button" className="btn btn-primary" onClick={requestAccessClicked}>Request Access</button>
                                </div>
                            </form>
                            <div className="mt-3">
                                <p className="text-bold">Are you the Authorized Officer? </p>
                                <p>You can <a href="/requestAccessToProvider">request to be the Authorized Officer</a> of this provider.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthContextProvider>
    );
}

export default RequestAccessToProvider;