import React from 'react';
import PropTypes from 'prop-types';
import { default as S } from 'react-select';

const Select = (props) => {
    const { label, id, options, errorMessage, customClass, optional, onChange, value} = props;
    return (
        <div>
            <div className="form-group">
                <label htmlFor={id}>{label}</label>
                <div className="multiselect-inform-wrapper">
                    <S options={options} className={customClass} id={id} name={id} onChange={onChange} value={value} />
                </div>
                {optional ? <span className="optional">(Optional)</span> : ''}
            </div>
            <div className="form-group">
                {errorMessage ? <div className="missing-field-message">{errorMessage}</div> : ''}
            </div>
        </div>
    );
}

Select.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })).isRequired
};

export default Select;