import React from 'react';
import { Link } from 'react-router-dom';

const RegisterRequestEmailVerificationSent = () => {
    return (
        <div className="login-wrapper mt-3">
            <div className="container">
                {/* <!-- Two Factor 		--> */}
                <div className="panel-clear">
                    <div className="row">
                        <div className="col">
                            <p className="text-center">
                                <img src="/images/ilus-email-alert.png" alt="Link illustration" className="mt-1" />
                            </p>
                        </div>
                        <div className="col-grow-2">
                            <div className="mt-3">
                                <h2>Verification email sent!</h2>
                            </div>
                            <p
                                className="p-primary"
                            >If your email is in our registers, you will receive a message in your inbox. Follow the instructions there.</p>
                            <div className="form-group mt-3">
                                <Link to="/main" className="btn btn-primary mr-2">Back to login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterRequestEmailVerificationSent;