import React, { useRef, useEffect, useContext } from 'react';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import numeral from 'numeral';
import { ProvidersContext } from '../../contexts/ProvidersContext';

const PieChart = (props) => {
    const { data, dollars } = props;
    const { npi } = useContext(ProvidersContext);

    const graphCanvas = useRef();

    useEffect(() => {

        let pieChartColors = [];
        const pieChartLabels = data.map((data) => {

            switch (data.carrierName || data.transactionStatus) {
                case "First Medical":
                    pieChartColors.push('rgba(255,100,131,1)');
                    break;
                case "MMM":
                    pieChartColors.push('rgba(255,159,64,1)');
                    break;
                case "Molina Healthcare":
                    pieChartColors.push('rgba(255,205,86,1)');
                    break;
                case "Plan De Salud Menonita":
                    pieChartColors.push('rgba(54,162,235,1)');
                    break;
                case "Triple S":
                    pieChartColors.push('rgba(74,192,192,1)');
                    break;
                case "Adjustment":
                    pieChartColors.push('rgba(255,100,131,1)');
                    break;
                case "Denied":
                    pieChartColors.push('rgba(255,159,64,1)');
                    break;
                case "Encounter":
                    pieChartColors.push('rgba(255,205,86,1)');
                    break;
                case "Paid":
                    pieChartColors.push('rgba(54,162,235,1)');
                    break;
                case "Reversal":
                    pieChartColors.push('rgba(74,192,192,1)');
                    break;
                case "Unknown":
                    pieChartColors.push('rgba(30,200,130,1)');
                    break;
                default:
                // code block
            }
            return data.carrierName || data.transactionStatus;;
        });

        let total = 0;
        const pieChartData = data.map((data) => {
            total += data.amountPaid || data.total;
            return data.amountPaid || data.total;
        });

        new Chart(graphCanvas.current, {
            plugins: [ChartDataLabels],
            type: 'pie',
            data: {
                labels: pieChartLabels,
                datasets: [{
                    label: 'Providers',
                    data: pieChartData,
                    backgroundColor: pieChartColors,
                    borderWidth: 2
                }]
            },
            options: {
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            // data for manipulation
                            if (dollars) {
                                return (
                                    ' $' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0.00')
                                );
                            }
                            return (
                                ' ' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0')
                            );
                        },
                    },
                },
                legend: {
                    align: 'start',
                    display: true,
                    labels: {
                        fontColor: 'rgb(0, 0, 0)',
                        fontSize: 10
                    },
                    position: 'right',
                },
                plugins: {
                    datalabels: {
                        color: "#000000",
                        formatter: function (value) {
                            return numeral(value / total * 100).format('0,0.00') + '%';
                        },
                        font: {
                            weight: 'bold',
                            size: 10,

                        }
                    }
                }
            }
        });
    }, [npi]);

    return (
        <div>
            <canvas ref={graphCanvas} />
        </div>
    );
}

export default PieChart;