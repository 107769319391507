import React from 'react';

const Glossary = (props) => {
    return (<div>
        <div class="container-with-sidebar__heading-bar sticky">
            <h1 class="container-with-sidebar__title">Glossary</h1>
        </div>

        <div>


            {/* <!-- Glossary Content --> */}
            <section className="container pt-4">
                <div className="question-list">
                    <div className="question-group row">
                        <div className="col"></div>
                        <div className="col">
                            <h3>Claim</h3>
                        </div>
                        <div className="col-grow-4">
                            <p>
                                Whether submitted manually or electronically, a bill for services, a line item of services, or a
                                bill detailing all services for one (1) Enrollee.
                            </p>
                        </div>
                    </div>
                    <div className="question-group row">
                        <div className="col"></div>
                        <div className="col">
                            <h3>Clean Claim</h3>
                        </div>
                        <div className="col-grow-4">
                            <p>
                                A Claim received by the Contractor for adjudication, which can be processed without obtaining
                                additional information from the Provider of the service or from a Third Party. It includes a
                                Claim with errors originating in the Contractor’s Claims system. It does not include a Claim
                                from a Provider who is under investigation for Fraud, Waste, or Abuse, or a Claim under review
                                to determine Medical Necessity.
                            </p>
                        </div>
                    </div>
                    <div className="question-group row">
                        <div className="col"></div>
                        <div className="col">
                            <h3>Encounter</h3>
                        </div>
                        <div className="col-grow-4">
                            <p>
                                A distinct set of services provided to an Enrollee in a face-to-face setting on the dates that
                                the services were delivered, regardless of whether the Provider is paid on a Fee-for-Service,
                                Capitated, salary, or alternative payment methodology basis. Encounters with more than one (1)
                                Provider, and multiple Encounters with the same Provider, that take place on the same day in the
                                same location will constitute a single Encounter, except when the Enrollee, after the first
                                Encounter, suffers an illness or injury requiring an additional diagnosis or treatment.
                            </p>
                        </div>
                    </div>
                    <div className="question-group row">
                        <div className="col"></div>
                        <div className="col">
                            <h3>Paid Date</h3>
                        </div>
                        <div className="col-grow-4">
                            <p>
                                For an encounter, this will be the date the transaction is processed by the carrier.
                                For non-encounters, this will be the date of payment for paid claims or the process date for
                                denied claims.
                            </p>
                        </div>
                    </div>
                    <div className="question-group row">
                        <div className="col"></div>
                        <div className="col">
                            <h3>Received Date</h3>
                        </div>
                        <div className="col-grow-4">
                            <p>Date when claim was received in carrier.</p>
                        </div>
                    </div>
                    <div className="question-group row">
                        <div className="col"></div>
                        <div className="col">
                            <h3>YTD</h3>
                        </div>
                        <div className="col-grow-4">
                            <p>Year To Date (From November 1, 2018)</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>);
}

export default Glossary;