import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import graphQL from '../../apis/graphQL';
import asesREST from '../../apis/asesREST';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
const TrelloWindow = () => {

    const location = useLocation();

    // refreshCounter
    const [refreshCounter, setRefreshCounter] = useState(0);
    // listName
    const [listName, setListName] = useState('');
    // inputName
    const [inputName, setInputName] = useState('');
    // inputDesc
    const [inputDesc, setInputDesc] = useState('');
    // trelloGetCards
    const [trelloGetCards, setTrelloGetCards] = useState([]);
    // show
    const [show, setShow] = useState(false);
    const fileInput = useRef();
    // buttonText
    const [buttonText, setButtonText] = useState('Show Trello');
    // style
    const [style, setStyle] = useState(
        {
            height: '0px',
            border: '0px',
            borderRadius: '0px',
            width: '125px'
        }
    );

    useEffect(() => {
        setTrelloGetCards([]);
        (async () => {
            // setLoading(true);
            const response = await graphQL(`mutation{
            trelloGetCards{
                name
                desc
                url
                labels{
                    name
                    color
                }
                list
            }
            }`);
            // setLoading(false);
            if (response.trelloGetCards) {
                setTrelloGetCards(response.trelloGetCards);
                setInputName('');
                setInputDesc('');
            } else if (response) {
                // setLoading(false);
                // setGraphQLErrors(response);
            }
        })()
    }, [refreshCounter]);

    const options = [
        {
            label: 'Doing',
            value: 'Doing'
        },
        {
            label: 'Design',
            value: 'Design'
        },
        {
            label: 'Development',
            value: 'Development',
        },
        {
            label: 'Bugs',
            value: 'Bugs',
        }
    ]

    const setColor = (list) => {
        let color = '';
        switch (list) {
            case 'Doing':
                color = 'green';
                break;
            case 'Design':
                color = 'rgb(111, 55, 176)';
                break;
            case 'Development':
                color = 'rgb(55, 103, 176)';
                break;
            case 'Bugs':
                color = 'red';
                break;
            default:
                break;
        }
        return color;
    }

    const renderLabels = (labels) => {
        return labels.map((label, index) => {
            return (

                <span key={index} style={{
                    fontSize: '10px',
                    backgroundColor: label.color,
                    color: 'white',
                    margin: '0px 3px',
                    padding: '1px 10px',
                    border: `1px solid ${label.color}`,
                    borderRadius: '10px'
                }}>{label.name}</span>

            )
        })
    }

    const renderCards = () => {
        return trelloGetCards.map((card, index) => {
            if (card.desc !== null) {
                if (card.desc.includes(location.pathname)) {
                    return (
                        <div className="trello-card" key={index}>
                            <span style={{ color: setColor(card.list) }}>{card.list}</span>
                            <br />
                            {renderLabels(card.labels)}
                            <br />
                            <span style={{ fontWeight: 'bold' }}>{card.name}</span>
                            <br />
                            <span>{card.desc}</span>
                            <br />
                            <a href={card.url}>{card.url}</a>
                            <hr />
                        </div>
                    );
                }
            }
        })
    }

    const showOrClose = () => {
        if (show) {
            setButtonText('Show Trello')
            setStyle({
                height: '0px',
                border: '0px',
                borderRadius: '10px',
                width: '125px'
            })
        } else {
            setButtonText('Close');
            setStyle({
                height: '500px',
                width: '300px',
                borderRadius: '10px',
                border: '2px solid rgb(55,136,176)'
            });
        }
        setShow(!show);
    }

    const save = async () => {
        var formData = new FormData();
        if (listName === '' || inputName === '' || inputDesc === '') {
            alert('List, name and description are required!');
            return;
        }
        formData.append("listName", listName);
        formData.append("name", inputName);
        formData.append("description", `(${location.pathname}) - ${inputDesc}`);

        if (fileInput.current.files[0] !== undefined) {
            formData.append("file", fileInput.current.files[0]);
        }

        try {
            asesREST.post(`/addCard`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                let counter = refreshCounter;
                counter += 1;
                setRefreshCounter(counter);
            });
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <Draggable enableUserSelectHack={false} >
            <div className="console" style={style} >
                <input type="file" ref={fileInput} className="trello-image-upload" />
                <div className="data" style={show ? {} : { display: 'none' }}>
                    {renderCards()}
                </div>
                <div style={show ? { position: 'relative', top: '-160px', left: '5px', zIndex: 1 } : { display: 'none' }} className="trello-select-div">
                    <Select options={options} id="" name="" onChange={(option) => { setListName(option.value) }} />
                </div>
                <textarea placeholder="Enter the title" className="trello-input-name" style={show ? {} : { display: 'none' }} onChange={(e) => { setInputName(e.target.value) }} value={inputName}></textarea>
                <textarea placeholder="Enter the description" className="trello-input-desc" style={show ? {} : { display: 'none' }} onChange={(e) => { setInputDesc(e.target.value) }} value={inputDesc}></textarea>
                <button className="trello-save" onClick={save} style={show ? {} : { display: 'none' }}>Save</button>
                <button className="trello-button" onClick={showOrClose}>{buttonText}</button>

            </div>
        </Draggable>
    );
}

export default TrelloWindow;