import React, { useState } from 'react';
import graphQL from '../../apis/graphQL';
import { Link } from 'react-router-dom';

const ProviderPendingRow = (props) => {
    const { firstName, lastName, npi } = props;

    const acceptConnection = async () => {
        //setLoading(true);
        const response = await graphQL(`mutation{
            acceptConnection(npi: ${npi}, firstName: "${firstName}", lastName: "${lastName}"){
                success
                message
            }
        }`);
        //setLoading(false);
        if (response.acceptConnection) {

        } else if (response) {
            //setLoading(false);
            //setGraphQLErrors(response);
        }
    }

    return (
        <tr className="clickable-row">
            <td>
                <strong>{`${firstName} ${lastName}`}</strong>
                <br />
                <span className="npi">NPI: {npi || 'npi'}</span>
            </td>
            <td>Authorized Representative</td>
            <td>Access Granted</td>
            <td>
                <Link onClick={acceptConnection} to="/providersList" className="btn btn-primary">Accept Connection</Link>
            </td>

            <td className="text-center">

            </td>
        </tr>

        // <tr>
        //     <td>
        //         <strong>Médico Lopez</strong>
        //         <br />
        //         <span className="npi">NPI. 988876554</span>
        //     </td>
        //     <td>Staff</td>
        //     <td>Access Granted</td>
        //     <td>
        //         <a href className="btn btn-primary">Accept Connection</a>
        //     </td>
        //     <td className="text-center"></td>
        // </tr>
        // <tr className="clickable-row" data-href="dashboard.html">
        //     <td>
        //         <strong>Miguel González</strong>
        //         <br />
        //         <span className="npi">NPI. 988876554</span>
        //     </td>
        //     <td>Authorized Official</td>
        //     <td>Active</td>
        //     <td>
        //         <span className="btn-group">
        //             <a href className="btn btn-green btn-no-width">
        //                 1
        //             <i className="far fa-comment"></i>
        //             </a>
        //             <a href className="btn btn-secondary btn-no-width pr-1 pl-1">Accept Connection</a>
        //         </span>
        //         <a href className="btn btn-secondary small">Edit</a>
        //     </td>
        //     <td className="text-center">
        //         <a href="dashboard.html" className="p-primary">
        //             <strong>
        //                 View this
        //                 provider
        //           </strong>
        //         </a>
        //     </td>
        // </tr>

    );
}

export default ProviderPendingRow;