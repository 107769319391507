import React, { createContext, useState } from 'react';
import socketIOClient from "socket.io-client";
export const SocketContext = createContext();

const SocketContextProvider = (props) => {
    // socket
    let socketDefinition = socketIOClient(process.env.REACT_APP_DEV_SERVER);
    const [socketIO, setSocketIO] = useState(socketDefinition);

    return (
        <SocketContext.Provider value={{ socketIO,setSocketIO }}>
            {props.children}
        </SocketContext.Provider>
    );
}

export default SocketContextProvider;