import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
    return (

        <div class="login-wrapper mt-3">
            <div class="container">
                <div class="panel-clear">
                    <div class="row">
                        <div class="col">
                            <p class="text-center">
                                <img src="images/404.png" alt="Page Not Found" class="mt-1" />
                            </p>
                        </div>
                        <div class="col-grow-2 mt-3">
                            <h2>Page Not Found</h2>
                            <p class="p-primary">The link is broken, or the page has been moved.</p>
                            <p class="form-group"><Link to="/providersList" class="btn btn-primary">Get me back to safety</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ErrorPage;