import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { LoggedUserContext } from '../../../contexts/LoggedUser';
import history from '../../../history';
import AuthContextProvider from '../../../contexts/AuthContext';
import graphQL from '../../../apis/graphQL';

const EmptyList = () => {

    // loading
    const [loading, setLoading] = useState(true);

    const { connectedProviders } = useContext(LoggedUserContext);

    useEffect(() => {
        if (connectedProviders.length === 0) {
            (async () => {
                setLoading(true);
                const response = await graphQL(`query{
                    providersPendingToAccept{
                    npi
                    firstName
                    lastName
                    providers{
                        _id
                        firstName
                        lastName
                        email
                        approved
                        accepted
                    }
                    }
                }`);
                if (response.providersPendingToAccept) {
                    setLoading(false);
                    history.push('/providersList');
                } else if (response) {
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })()
        } else {
            history.push('/providersList');
        }
    }, [connectedProviders]);

    return (
        <AuthContextProvider>
            <div>
                {loading ? <div className="loader"></div> :
                    <div>
                        <div className="container-with-sidebar__content-block">
                            <div className="row row--centered">
                                <div className="col">
                                    <h1 className="container-with-sidebar__title">Connected Providers <small className="isnumber">(0)</small></h1>
                                </div>
                                <div className="col text-right">
                                    <Link to="/selectProviderType" className="btn-link-fancy small-text">Add Providers <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{ color: 'rgb(55,136,176)' }} /></Link>
                                </div>
                            </div>
                        </div>
                        <section className="container-with-sidebar__content-block">
                            <div className="row mt-5 mb-5">
                                <div className="col text-right">
                                    <img className="mr-2" src="/images/ilustracion-empty-providers.png" alt="Icons depicting a user provider and some medichal buildings" />
                                </div>
                                <div className="col-grow-2">
                                    <h1>
                                        You don’t have any connected providers.
                    </h1>
                                    <p className="p-primary">
                                        <Link to="/selectProviderType">Start by adding one.</Link>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                }
            </div>
        </AuthContextProvider>);
}

export default EmptyList;