import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import history from './history';
import Main from './components/pages/Main';
import Step1 from './components/pages/createAccount/Step1';
import Step2 from './components/pages/createAccount/Step2';
import Step3 from './components/pages/createAccount/Step3';
import CompletePersonalInformation from './components/pages/firstTimeLogin/CompletePersonalInformation';
import EmailPasswordRecovery from './components/pages/forgotPassword/EmailPasswordRecovery';
import MethodPasswordRecovery from './components/pages/forgotPassword/MethodPasswordRecovery';
import NewPasswordRecovery from './components/pages/forgotPassword/NewPasswordRecovery';
import QuestionsPasswordRecovery from './components/pages/forgotPassword/QuestionsPasswordRecovery';
import SuccessPasswordRecovery from './components/pages/forgotPassword/SuccessPasswordRecovery';
import TokenPasswordRecovery from './components/pages/forgotPassword/TokenPasswordRecovery';
import VerificationCode from './components/pages/login/VerificationCode';
import FaqRegister from './components/pages/needHelp/FaqRegister';
import RegisterRequestEmailVerificationSent from './components/pages/needHelp/RegisterRequestEmailVerificationSent';
import RegisterRequestEmailVerification from './components/pages/needHelp/RegisterRequestEmailVerification';
import Connected from './components/pages/providers/Connected';
import Disclosure from './components/pages/providers/Disclosure';
import List from './components/pages/providers/List';
import PermissionVerification from './components/pages/providers/PermissionVerification';
import Search from './components/pages/providers/Search';
import Step4 from './components/pages/createAccount/Step4';
import RegistrationContextProvider from './contexts/RegistrationContext';
import TermsAndConditionsPage from './components/pages/TermsAndConditionsPage';
import LoggedUserContextProvider from './contexts/LoggedUser';
import ProvidersContextProvider from './contexts/ProvidersContext';
import ForgotPasswordContextProvider from './contexts/ForgotPasswordContext';
import Billing from './components/pages/providers/Billing';
import ProvidersNetworkAndPopulation from './components/pages/providers/ProvidersNetworkAndPopulation';
import AccountSidebar from './components/layouts/AccountSidebar';
import DashboardSidebar from './components/layouts/DashboardSidebar';
import EmptyList from './components/pages/providers/EmptyList';
import HeaderAndFooterOnly from './components/layouts/HeaderAndFooterOnly';
import Glossary from './components/pages/Glossary';
import PersonalInformation from './components/pages/account/PersonalInformation';
import SecurityQuestions from './components/pages/account/SecurityQuestions';
import ChangePassword from './components/pages/account/ChangePassword';
import Connections from './components/pages/providers/Connections';
import AddConnection from './components/pages/providers/AddConnection';
import AddConnectionConfirmed from './components/pages/providers/AddConnectionConfirmed';
import RequestAccessToProvider from './components/pages/providers/RequestAccessToProvider';
import ProviderDisclosureFromRequest from './components/pages/providers/ProviderDisclosureFromRequest';
import ProviderRequestConfirmed from './components/pages/providers/ProviderRequestConfirmed';
import SocketContextProvider from './contexts/SocketContext';
import SelectProviderType from './components/pages/providers/SelectProviderType';
import SearchPMG from './components/pages/providers/SearchPMG';
import PermissionVerificationPMG from './components/pages/providers/PermissionVerificationPMG';
import AccountSuspended from './components/pages/account/AccountSuspended';
import AccountSidebarSuspended from './components/layouts/AccountSidebarSuspended';
import TermsAndAgreementsContextProvider from './contexts/TermsAndAgreementsContext';
import ErrorPage from './components/ErrorPage';
import TrelloOpenTasks from './components/TrelloOpenTasks';

function RouteWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}

const App = () => {

  //For google analytics
  const trackingId = "UA-156022292-1";
  ReactGA.initialize(trackingId);

  // Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (
    <TermsAndAgreementsContextProvider>
      <SocketContextProvider>
        <RegistrationContextProvider>
          <ForgotPasswordContextProvider>
            <LoggedUserContextProvider>
              <ProvidersContextProvider>
                <Router history={history}>

                  <Switch>
                    <RouteWrapper exact path="/main" component={Main} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/createAccountStep1" component={Step1} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/createAccountStep2" component={Step2} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/createAccountStep3" component={Step3} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/createAccountStep4" component={Step4} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/" component={TermsAndConditionsPage} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/verificationCode" component={VerificationCode} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/emailPasswordRecovery" component={EmailPasswordRecovery} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/methodPasswordRecovery" component={MethodPasswordRecovery} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/newPasswordRecovery" component={NewPasswordRecovery} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/questionsPasswordRecovery" component={QuestionsPasswordRecovery} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/successPasswordRecovery" component={SuccessPasswordRecovery} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/tokenPasswordRecovery" component={TokenPasswordRecovery} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/faq" component={FaqRegister} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/resendEmailRequest" component={RegisterRequestEmailVerification} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/resendEmailRequestSent" component={RegisterRequestEmailVerificationSent} layout={HeaderAndFooterOnly} />
                    <RouteWrapper exact path="/completePersonalInformation" component={CompletePersonalInformation} layout={AccountSidebar} />
                    <RouteWrapper exact path="/accountSuspended" component={AccountSuspended} layout={AccountSidebarSuspended} />
                    <RouteWrapper exact path="/emptyList" component={EmptyList} layout={AccountSidebar} />
                    <RouteWrapper exact path="/selectProviderType" component={SelectProviderType} layout={AccountSidebar} />
                    <RouteWrapper exact path="/searchProvider" component={Search} layout={AccountSidebar} />
                    <RouteWrapper exact path="/searchProviderPMG" component={SearchPMG} layout={AccountSidebar} />
                    <RouteWrapper exact path="/requestAccessToProvider" component={RequestAccessToProvider} layout={AccountSidebar} />
                    <RouteWrapper exact path="/providerDisclosureFromRequest" component={ProviderDisclosureFromRequest} layout={AccountSidebar} />
                    <RouteWrapper exact path="/providerRequestConfirmed" component={ProviderRequestConfirmed} layout={AccountSidebar} />
                    <RouteWrapper exact path="/permissionVerification" component={PermissionVerification} layout={AccountSidebar} />
                    <RouteWrapper exact path="/permissionVerificationPMG" component={PermissionVerificationPMG} layout={AccountSidebar} />
                    <RouteWrapper exact path="/disclosure" component={Disclosure} layout={AccountSidebar} />
                    <RouteWrapper exact path="/connected" component={Connected} layout={AccountSidebar} />
                    <RouteWrapper exact path="/providersList" component={List} layout={AccountSidebar} />
                    <RouteWrapper exact path="/glossary" component={Glossary} layout={DashboardSidebar} />
                    <RouteWrapper exact path="/billing" component={Billing} layout={DashboardSidebar} />
                    <RouteWrapper exact path="/providersNetworkAndPopulation" component={ProvidersNetworkAndPopulation} layout={DashboardSidebar} />
                    <RouteWrapper exact path="/providerConnections" component={Connections} layout={DashboardSidebar} />

                    <RouteWrapper exact path="/personalInformation" component={PersonalInformation} layout={AccountSidebar} />
                    <RouteWrapper exact path="/securityQuestions" component={SecurityQuestions} layout={AccountSidebar} />
                    <RouteWrapper exact path="/changePassword" component={ChangePassword} layout={AccountSidebar} />
                    <RouteWrapper exact path="/addConnection" component={AddConnection} layout={DashboardSidebar} />
                    <RouteWrapper exact path="/addConnectionConfirmed" component={AddConnectionConfirmed} layout={DashboardSidebar} />
                    <RouteWrapper exact path="/openTasks" component={TrelloOpenTasks} layout={HeaderAndFooterOnly} />
                    <RouteWrapper path="*" component={ErrorPage} layout={HeaderAndFooterOnly} />
                  </Switch>

                </Router>
              </ProvidersContextProvider>
            </LoggedUserContextProvider>
          </ForgotPasswordContextProvider>
        </RegistrationContextProvider>
      </SocketContextProvider>
    </TermsAndAgreementsContextProvider>
  );
}

export default App;
