import React, { useContext, useEffect } from 'react';
import { ForgotPasswordContext } from '../../../contexts/ForgotPasswordContext';
import history from '../../../history';
import { Link } from 'react-router-dom';

const MethodPasswordRecovery = (props) => {


    const { email, maskedEmail } = useContext(ForgotPasswordContext);

    useEffect(() => {
        if (email === '') {
            history.push('/emailPasswordRecovery')
        }
    })

    return (
        <div>
            <div className="login-wrapper mt-3">
                <div className="container">
                    {/* <!-- Two Factor 		--> */}
                    <div className="panel-clear">
                        <div className="row gap-triple">
                            <div className="col">
                                <h2>Password Recovery</h2>
                                <p className="text-center">
                                    <img
                                        src="/images/image-password-recovery.png"
                                        alt="Illustration depicting a lock and password"
                                        className="mt-1"
                                    />
                                </p>
                            </div>
                            <div className="col-grow-2">
                                <p className="p-primary text-bold">Select your preferred recovery password method:</p>
                                <Link to="/questionsPasswordRecovery" className="btn big-option">
                                    Answer my security questions
                    <i className="fas fa-chevron-right"></i>
                                </Link>
                                <Link to="/tokenPasswordRecovery" className="btn big-option">
                                    Send a token to my email ({maskedEmail})
                    <i className="fas fa-chevron-right"></i>
                                </Link>
                                <p className="mt-2">
                                    <Link to="/main" className="btn btn-secondary mr-2">Cancel</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MethodPasswordRecovery;