import React, { useState, useContext } from 'react';
import graphQL from '../../apis/graphQL';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LoggedUserContext } from '../../contexts/LoggedUser';

const updatedAlert = withReactContent(Swal);

const TrelloRow = (props) => {

    // loading
    const [loading, setLoading] = useState(false);
    const { firstName, lastName } = useContext(LoggedUserContext);

    const { id, idListName, name, desc, labels, shortUrl } = props.task;

    const labelsArray = [];
    labels.forEach((label) => {
        labelsArray.push(label.name);
    });

    const moveToDone = async (cardId) => {
        setLoading(true);

        updatedAlert.fire({
            title: 'Enter the comment',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Move to Done',
            showLoaderOnConfirm: true,
            preConfirm: async (comment) => {
                comment = `${comment} (Moved to Done list by ${firstName} ${lastName})`;
                const response = await graphQL(`mutation{
                    trelloCardMoveToDone(cardId: "${cardId}",comment:"${comment}", name:"${name}",description:"${desc}",shortUrl:"${shortUrl}",firstName:"${firstName}",lastName:"${lastName}"){
                        success
                        message
                    }
                    }`);
                setLoading(false);
                if (response.trelloCardMoveToDone) {
                    props.updateList();
                    setLoading(false);
                } else if (response) {
                    setLoading(false);
                    //  setGraphQLErrors(response);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()

        }).then((result) => {
            setLoading(false);
        })


    }

    return (
        <tr className="clickable-row">
            <td>
                {idListName}
            </td>
            <td>
                {name}
            </td>
            <td>
                {desc}
            </td>
            <td>
                {labelsArray.toString()}
            </td>
            <td>
                <a href={shortUrl}>{shortUrl}</a>
            </td>
            <td>
                <a onClick={() => { moveToDone(id) }} className="btn btn-secondary">{loading ? <div className="loader"></div> : 'Mark as Done'}</a>
            </td>
        </tr>

    );
}

export default TrelloRow;