import React from 'react';
import { Link } from 'react-router-dom';

const SuccessPasswordRecovery = (props) => {
    return (
        <div>
            <div className="login-wrapper mt-3">
                <div className="container">
                    {/* <!-- Two Factor 		--> */}
                    <div className="panel-clear">
                        <div className="row gap-triple">
                            <div className="col">
                                <h2>Password Recovery</h2>
                                <p className="text-center">
                                    <img
                                        src="/images/image-password-recovery.png"
                                        alt="Illustration depicting a lock and password"
                                        className="mt-1"
                                    />
                                </p>
                            </div>
                            <div className="col-grow-2">
                                <p className="message message-success">Your password has been reset.</p>
                                <p>
                                    <Link to="/main" className="btn btn-primary mr-2">Log in</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessPasswordRecovery;