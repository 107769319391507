import React, { createContext, useState, useEffect } from 'react';

export const ForgotPasswordContext = createContext();

const ForgotPasswordContextProvider = (props) => {
    //email
    const [email, setEmail] = useState('');
    // maskedEmail
    const [maskedEmail, setMaskedEmail] = useState('');
    // token
    const [token, setToken] = useState('');

    useEffect(() => {
        let output = email.replace(/^(.{2})[^@]+/, "$1XXX");
        setMaskedEmail(output);
    }, [email]);

    return (
        <ForgotPasswordContext.Provider value={{ email, setEmail, maskedEmail, setMaskedEmail, token, setToken }}>
            {props.children}
        </ForgotPasswordContext.Provider>
    );
}

export default ForgotPasswordContextProvider;