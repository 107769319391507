import React, { createContext, useEffect, useContext, useState } from 'react';
import graphQL from '../apis/graphQL';
import { LoggedUserContext } from '../contexts/LoggedUser';
import history from '../history';
import { ProvidersContext } from '../contexts/ProvidersContext';
import { SocketContext } from './SocketContext';
//import socketIOClient from "socket.io-client";
import { useLocation } from 'react-router-dom';



export const AuthContext = createContext();

const AuthContextProvider = (props) => {

    const location = useLocation();

    const { socketIO } = useContext(SocketContext);
    // status
    const [status, setStatus] = useState('');

    const { setProfile, setFirstTimeLogin, setConnectedProviders, setFirstName, setLastName, setEmail, setSocketIO, setId, id, setReason, setAdditionalReason } = useContext(LoggedUserContext);
    const { setNpi, setCurrentNpiName } = useContext(ProvidersContext);

    console.log(location.pathname);

    useEffect(() => {
        if (socketIO) {
            socketIO.on('accountSuspended', (result) => {
                setReason(result.reason);
                setAdditionalReason(result.additionalReason);

                console.log('socket io account suspended');
                console.log('resultID', result.id);
                console.log('id', id);
                console.log(result.id == id);
                if (result.status === 'suspended' && result.id == id) {
                    history.push('/accountSuspended');
                } else {
                    history.push('/billing')
                }
            })
        }

    }, [socketIO, id]);

    useEffect(() => {
        console.log('status', status);
        if (status === 'suspended' && location.pathname !== '/verificationCode') {
            history.push('/accountSuspended');
        }
    }, [status]);


    useEffect(() => {
        (async () => {
            const response = await graphQL(`query{
                verifyAuthentication{
                    _id
                    status
                    email
                    profile
                    firstTimeLogin
                    verificationToken
                    currentNpi
                    currentNpiName
                    reason
                    additionalReason
                    connectedProviders{
                        npi
                        firstName
                        lastName
                        role
                        status
                        isPMG
                    }
                    personalInformation{
                        firstName
                        lastName
                    }
                    }
                }`);
            if (response.verifyAuthentication) {
                if (response.verifyAuthentication.verificationToken !== null && response.verifyAuthentication.verificationToken !== '') {
                    history.push('/verificationCode');
                }
                console.log('fsfsdssdsssfs');
                console.log(response.verifyAuthentication);

                const personalInformation = response.verifyAuthentication.personalInformation || '';
                const { _id, profile, firstTimeLogin, email, connectedProviders, currentNpi, currentNpiName, status } = response.verifyAuthentication;
                const reason = response.verifyAuthentication.reason || '';
                const additionalReason = response.verifyAuthentication.additionalReason || '';
                console.log('Auth Status', status);
                setReason(reason);
                setAdditionalReason(additionalReason);
                setId(_id);
                setStatus(status);
                setEmail(email);
                setFirstName(personalInformation.firstName || '');
                setLastName(personalInformation.lastName || '');
                setProfile(profile);
                setNpi(currentNpi);
                setCurrentNpiName(currentNpiName);
                setFirstTimeLogin(firstTimeLogin);
                setConnectedProviders(connectedProviders === null ? [] : connectedProviders);
            } else {
                console.log(response);
                history.push('/');
            }
        })()
    }, []);

    return (
        <AuthContext.Provider >
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;