import React, { useRef, useEffect, useContext, useState } from 'react';
import Chart from 'chart.js';
import numeral from 'numeral';
import { ProvidersContext } from '../../contexts/ProvidersContext';

const LineChartFilledTwoDatasets = (props) => {
    const { data, dollars } = props;
    // table
    const [table, setTable] = useState('');
    const labels = [];
    const amountPaid = [];
    const amountAllowed = [];
    const graphCanvas = useRef();
    const { npi } = useContext(ProvidersContext);

    let year = parseInt(data[0].date.year);
    let month = parseInt(data[0].date.month);

    useEffect(() => {
        let x = 1
        do {
            if (month === 0) {
                month = 12;
                year--;
            }
            let monthName = '';
            switch (month) {
                case 1:
                    monthName = 'JAN'
                    break;
                case 2:
                    monthName = 'FEB'
                    break;
                case 3:
                    monthName = 'MAR'
                    break;
                case 4:
                    monthName = 'APR'
                    break;
                case 5:
                    monthName = 'MAY'
                    break;
                case 6:
                    monthName = 'JUN'
                    break;
                case 7:
                    monthName = 'JUL'
                    break;
                case 8:
                    monthName = 'AUG'
                    break;
                case 9:
                    monthName = 'SEP'
                    break;
                case 10:
                    monthName = 'OCT'
                    break;
                case 11:
                    monthName = 'NOV'
                    break;
                case 12:
                    monthName = 'DEC'
                    break;

                default:
                    break;
            }
            labels.push(`${monthName}-${year}`);
            const res = data.filter((data) => {
                return parseInt(data.date.month) === month && parseInt(data.date.year) === year;
            });
            if (res.length > 0) {
                amountAllowed.push(res[0].amountAllowed);
                amountPaid.push(res[0].amountPaid);
            } else {
                amountAllowed.push(0);
                amountPaid.push(0);
            }
            month--;
            x++;
        } while (x <= 12)

        let amountPaidTotal = 0;
        let amountAllowedTotal = 0;

        amountPaid.map((value) => {
            amountPaidTotal += value;
        });

        amountAllowed.map((value) => {
            amountAllowedTotal += value;
        });

        if (props.showTable) {
            let ta = labels.map((label, x) => {
                if (x === labels.length - 1) {
                    return (
                        <React.Fragment key={x}>
                            <tr >
                                <td>{label}</td>
                                <td className="text-right">${numeral(amountPaid[x]).format('0,0.00')}</td>
                                <td className="text-right">${numeral(amountAllowed[x]).format('0,0.00')}</td>
                            </tr>
                            <tr className="text-bold">
                                <td>Total</td>
                                <td className="text-right">${numeral(amountPaidTotal).format('0,0.00')}</td>
                                <td className="text-right">${numeral(amountAllowedTotal).format('0,0.00')}</td>
                            </tr>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <tr key={x}>
                            <td>{label}</td>
                            <td className="text-right">${numeral(amountPaid[x]).format('0,0.00')}</td>
                            <td className="text-right">${numeral(amountAllowed[x]).format('0,0.00')}</td>
                        </tr>
                    )
                }
            });
            setTable(ta);
        } else {

            Chart.defaults.global.elements.line.tension = 0;
            new Chart(graphCanvas.current, {
                type: 'line',
                data: {
                    labels: labels.reverse(),
                    datasets: [
                        {
                            type: 'line',
                            label: "Amount Paid",
                            data: amountPaid.reverse(),
                            backgroundColor: 'rgba(74,192,192)',
                            borderColor: 'rgba(0, 0, 0, 0)',
                            yAxisID: 'allowed',
                        },
                        {
                            label: "Amount Allowed",
                            data: amountAllowed.reverse(),
                            borderColor: 'rgba(0, 0, 0, 0)',
                            backgroundColor: 'rgba(255,100,131,1)',
                            yAxisID: 'allowed',
                        }
                    ]
                },
                options: {
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                // data for manipulation
                                return ' $' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0.00');
                            },

                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                id: "allowed",
                                ticks: {
                                    beginAtZero: true,
                                    callback: function (label) {
                                        if (label < 1000) {
                                            return label;
                                        } else {
                                            return label / 1000 + 'k';
                                        }
                                    }
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: ''
                                }
                            }
                        ]
                    },
                }
            });
        }

    }, [npi]);

    if (props.showTable) {
        if (table === '') {
            return <tr></tr>
        }
        return <React.Fragment>{table}</React.Fragment>;
    } else {
        return (
            <div>
                <canvas ref={graphCanvas} />
            </div>
        );
    }
}

export default LineChartFilledTwoDatasets;