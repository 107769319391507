import React, { useState, useContext } from 'react';
import Input from '../../reusable/Input';
import { Link } from 'react-router-dom';
import graphQL from '../../../apis/graphQL';
import { ForgotPasswordContext } from '../../../contexts/ForgotPasswordContext';
import history from '../../../history';

const EmailPasswordRecovery = (props) => {

    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState(false);
    // loading
    const [loading, setLoading] = useState(false);
    // disable
    const [disable, setDisable] = useState(true);

    const { setEmail } = useContext(ForgotPasswordContext);

    //onBlur email verifies if the email is valid and if the email exists in the database
    const onBlur = async (e) => {
        // Reset Errors
        setEmail(e.target.value);
        setDisable(true);
        setGraphQLErrors(false);
        setLoading(true);
        const response = await graphQL(`query{
            verifyIfEmailExists(email: "${e.target.value}"){
                success
                message
            }
        }`);
        setLoading(false);
        if (response.verifyIfEmailExists) {
            setGraphQLErrors('Email don\'t exists');
        } else {
            setDisable(false)
        }
    }

    const continueButtonClicked = () => {
        history.push('/methodPasswordRecovery');
    }

    return (
        <div>
            <div className="login-wrapper mt-3">
                <div className="container">
                    {/* <!-- Two Factor 		--> */}
                    <div className="panel-clear">
                        <div className="row gap-triple">
                            <div className="col">
                                <h2>Password Recovery</h2>
                                <p className="text-center">
                                    <img
                                        src="/images/image-password-recovery.png"
                                        alt="Illustration depicting a lock and password"
                                        className="mt-1"
                                    />
                                </p>
                            </div>
                            <div className="col-grow-2">
                                <p className="p-primary text-bold">Enter the email for your account:</p>
                                <div>
                                    <Input
                                        type="text"
                                        label="Enter the email for your account"
                                        id="email"
                                        placeholder="email"
                                        errorMessage={graphQLErrors}
                                        customClass="input-w400"
                                        onChange={() => setDisable(true)}
                                        onBlur={onBlur}
                                        fancy
                                    />
                                    <div className="form-group mt-3">
                                        <Link to="/main" className="btn btn-secondary mr-2">Cancel</Link>
                                        <button
                                            onClick={continueButtonClicked}
                                            disabled={disable}
                                            type="button"
                                            className="btn btn-primary"
                                        >{loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> : 'Continue'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EmailPasswordRecovery;