import React from 'react';

const Header = (props) => {
    return (
        <div className="container mb-1 mt-2">
            <header className="main">
                <div className="header-left header-col">
                    <ul className="logo-list-header">
                        <li><img src="images/logo-psc.svg" alt="PSC" /></li>
                    </ul>
                </div>
                <div className="header-center header-col">
                    <h1>Providers Service Center</h1>
                </div>
                <div className="header-right header-col">
                    <ul className="logo-list-header">
                        <li><img src="images/gob-pr-xs.png" alt="Gobierno de Puerto Rico" /></li>
                        <li><img src="images/logo-ases-2018-2.svg"
                            alt="ASES Administración de Seguros de Salud de Puerto Rico" className="smaller" /></li>
                        <li><img src="images/logo-vital-2018-2.svg" alt="Vital Salud en tus manos" /></li>
                    </ul>
                </div>
            </header>
        </div>
    );
}

export default Header;

 // < div className = "container mb-1 mt-2" >
        //     <header className="main">
        //         <div className="header-left header-col">
        //             <ul className="logo-list-header">
        //                 <li><img src="/images/logo-vital-2018-2.svg" alt="Vital Salud en tus manos" /></li>
        //             </ul>
        //         </div>
        //         <div className="header-center header-col">
        //             <h1>Providers Service Center</h1>
        //         </div>
        //         <div className="header-right header-col col-adjustment">
        //             <ul className="logo-list-header">
        //                 <li><img src="/images/gob-pr-xs.png" alt="Gobierno de Puerto Rico" /></li>
        //                 <li><img src="/images/logo-ases-2018-2.svg"
        //                     alt="ASES Administración de Seguros de Salud de Puerto Rico" className="smaller" /></li>
        //             </ul>
        //         </div>
        //     </header>
        // </div > 