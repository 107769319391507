import React, { useContext, useEffect, useState } from 'react';
import Footer from '../Footer'
import UserDropDown from '../reusable/UserDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserMd, faUserCircle, faLock, faAsterisk, faHospital, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import ProvidersDropDown from '../reusable/ProvidersDropDown';

const AccountSidebarSuspended = (props) => {

    const { firstTimeLogin } = useContext(LoggedUserContext);

    useEffect(() => {
        console.log(props.location.pathname);
    }, [props.location.pathname]);

    const menuItems = [
        {
            icon: faUserCircle,
            name: 'Personal Info',
            path: '/personalInformation',
            disabled: true
        },
        {
            icon: faShieldAlt,
            name: 'Security Questions',
            path: '/securityQuestions',
            disabled: true,
        },
        {
            icon: faLock,
            name: 'Password',
            path: '/changePassword',
            disabled: true
        },
        {
            icon: faHospital,
            name: 'Connected Providers',
            path: '/emptyList',
            disabled: true
        },
    ]

    // initialSidebarClass
    const [initialSidebarClass, setInitialSidebarClass] = useState('container-with-sidebar__sidebar--full');
    // initialContentClass
    const [initialContentClass, setInitialContentClass] = useState('container-with-sidebar__content js-toggle-content');
    // iconOnlyInitialSidebarClass
    const [iconOnlyInitialSidebarClass, setIconOnlyInitialSidebarClass] = useState('main-menu-vertical__item-list');

    const toggleVisibility = () => {
        if (initialSidebarClass === 'container-with-sidebar__sidebar--full') {
            setIconOnlyInitialSidebarClass('main-menu-vertical__item-list main-menu-vertical__item-list--icon-only');
            setInitialSidebarClass('container-with-sidebar__sidebar--narrow');
        } else {
            setIconOnlyInitialSidebarClass('main-menu-vertical__item-list');
            setInitialSidebarClass('container-with-sidebar__sidebar--full');
        }

        if (initialContentClass === 'container-with-sidebar__content js-toggle-content') {
            setInitialContentClass('container-with-sidebar__content container-with-sidebar__content--wide js-toggle-content')
        } else {
            setInitialContentClass('container-with-sidebar__content js-toggle-content')
        }


    }

    const renderMenuItems = () => {
        return menuItems.map((menu) => {
            if (menu.disabled) {
                return <li key={menu.name} className="disabled" style={{ listStyle: 'none' }}><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={menu.icon} /></span> <span className="main-menu-vertical__label">{menu.name}</span></li>
            }
            if (menu.path === '/emptyList' && (props.location.pathname === '/providersList' || props.location.pathname === '/selectProviderType' || props.location.pathname === '/searchProvider' || props.location.pathname === '/permissionVerification' || props.location.pathname === '/requestAccessToProvider' || props.location.pathname === '/disclosure' || props.location.pathname === '/connected')) {
                return <li key={menu.name} className="current" style={{ listStyle: 'none' }}><Link to={menu.path}><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={menu.icon} /></span> <span className="main-menu-vertical__label">{menu.name}</span></Link></li>
            }
            return <li key={menu.name} className={menu.path === props.location.pathname ? "current" : ''} style={{ listStyle: 'none' }}><Link to={menu.path}><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={menu.icon} /></span> <span className="main-menu-vertical__label">{menu.name}</span></Link></li>
        })
    }

    return (

        <div>
            {/* <!-- Header Slim --> */}
            <div className="container--fixed-top">
                <header className="main main--slim">
                    <div className="header-left header-col">
                        <button onClick={toggleVisibility} type="button" className="hamburguer-button  js-toggle-sidebar-btn "><FontAwesomeIcon icon={faBars} /></button><span className="ml-1 mr-1"><img className="logo-header" src="/images/logo-psc.svg" alt="PSC" /></span>
                    </div>
                    <div className="header-center header-col text-center">
                        {/* <ProvidersDropDown selectProvider /> */}
                    </div>
                    <div className="header-right header-col">
                        <UserDropDown />
                    </div>
                </header>
            </div>
            {/* <!-- End Header Slim-- > */}

            <div className="container-with-sidebar has-container--fixed-top">
                {/* <!-- Sidebar for menu--> */}
                <div className={`container-with-sidebar__sidebar js-toggle-sidebar ${initialSidebarClass}`}>
                    {/* <!-- Menu Principal --> */}
                    <nav className="main-menu-vertical">
                        <ul className={`${iconOnlyInitialSidebarClass} js-toggle-label`}>
                            <li className="main-menu-vertical--title-lvl1" style={{ listStyle: 'none' }}><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faUserMd} /></span><span className="main-menu-vertical__label"> Account</span></li>

                            {renderMenuItems()}



                            {/* <li className="current"><a href="account-personal-information.html"><FontAwesomeIcon icon={faUserCircle} /> Personal Info</a></li>
                            <li ><a href="account-personal-information.html"><FontAwesomeIcon icon={faLock} /> Security Questions</a></li>
                            <li className="disabled"><a href="account-personal-information.html"><FontAwesomeIcon icon={faAsterisk} /> Password</a></li>
                            <li className="disabled"><a href="account-personal-information.html"><FontAwesomeIcon icon={faHospital} /> Connected Providers</a></li> */}
                        </ul>
                    </nav>
                    {/* <!-- End Menu Principal --> */}
                </div>
                {/* <!-- End Sidebar for menu--> */}
                {/* <!-- Main Content--> */}
                <div className={initialContentClass}>
                    {props.children}
                    {/* <!-- Footer --> */}
                    <Footer />
                </div>
            </div>
        </div>

    );
}

export default AccountSidebarSuspended;