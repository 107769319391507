import React, { useContext, useEffect, useState } from 'react';
import { LoggedUserContext } from '../../../contexts/LoggedUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ConnectionRow from '../../reusable/ConnectionRow';
import { Link } from 'react-router-dom';
import AuthContextProvider from '../../../contexts/AuthContext';
import graphQL from '../../../apis/graphQL';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import { SocketContext } from '../../../contexts/SocketContext';

const Connections = () => {

    // loading
    const [loading, setLoading] = useState(false);
    // currentUserRole
    const [currentUserRole, setCurrentUserRole] = useState('');
    const { socketIO } = useContext(SocketContext);
    const { connectedUsers, setConnectedUsers, email, firstName, lastName, connectedProviders } = useContext(LoggedUserContext);
    const { npi } = useContext(ProvidersContext);
    // updateCounter
    const [updateCounter, setUpdateCounter] = useState(0);
    // primaryRepresentative
    const [primaryRepresentative, setPrimaryRepresentative] = useState('');
    // primaryRepresentativeFirstName
    const [primaryRepresentativeFirstName, setPrimaryRepresentativeFirstName] = useState('');
    // primaryRepresentativeLastName
    const [primaryRepresentativeLastName, setPrimaryRepresentativeLastName] = useState('');
    // primaryRepresentativeEmail
    const [primaryRepresentativeEmail, setPrimaryRepresentativeEmail] = useState('');


    useEffect(() => {
        connectedProviders.filter((cp) => {
            if (cp.npi === npi) {
                if (cp.role === 0) {
                    setCurrentUserRole('Primary Representative');
                } else {
                    setCurrentUserRole('Authorized Representative');
                }
            }
        });

        renderConnectedProviders();
    }, [connectedUsers, npi]);

    useEffect(() => {
        (async () => {
            if (currentUserRole === 'Authorized Representative' && primaryRepresentative !== '') {
                setLoading(true);
                console.log('primary representative', primaryRepresentative);
                const response = await graphQL(`query{
                getBasicUserInformation(_id: "${primaryRepresentative}"){
                    firstName
                    lastName
                    email
                }
                }`);
                setLoading(false);
                console.log('response basic information');
                console.log(response.getBasicUserInformation);
                if (response.getBasicUserInformation) {
                    setPrimaryRepresentativeFirstName(response.getBasicUserInformation.firstName);
                    setPrimaryRepresentativeLastName(response.getBasicUserInformation.lastName);
                    setPrimaryRepresentativeEmail(response.getBasicUserInformation.email);
                } else if (response) {
                    setLoading(false);
                    //setGraphQLErrors(response);
                }
            }
        })()
    }, [currentUserRole]);

    useEffect(() => {
        let counter = updateCounter + 1;
        if (socketIO) {
            socketIO.on('updateAuthorizedRepresentatives', () => {
                setUpdateCounter(counter);
            })
        }
    }, [socketIO, updateCounter]);

    useEffect(() => {
        if (npi !== '') {
            setConnectedUsers([]);
            (async () => {
                setLoading(true);
                const response = await graphQL(`
            query{
            npiToAssociate(npi: ${npi}){
                npiToAssociate{
                _id
                npi
                primaryRepresentative
                authorizedRepresentatives{
                    _id
                    firstName
                    lastName
                    email
                    approved
                    accepted
                }
                }
            }
            }`);
                setLoading(false);
                if (response.npiToAssociate) {
                    console.log('npiToAssociate');
                    console.log(response.npiToAssociate);
                    setPrimaryRepresentative(response.npiToAssociate.npiToAssociate[0].primaryRepresentative);
                    setConnectedUsers(response.npiToAssociate.npiToAssociate[0].authorizedRepresentatives);
                } else if (response) {
                    setLoading(false);
                    //  setGraphQLErrors(response);
                }
            })()
        }
    }, [npi, updateCounter])

    const renderConnectedProviders = () => {
        return connectedUsers.map((connectedUser) => {
            return <ConnectionRow firstName={connectedUser.firstName} lastName={connectedUser.lastName} email={connectedUser.email} key={connectedUser.email} status={connectedUser.approved} rol='Authorized Representative' _id={connectedUser._id} npi={npi} />
        })
    }

    return (
        <AuthContextProvider>
            <div>
                <div className="container-with-sidebar__heading-bar sticky">
                    <div className="row row--centered">
                        <div className="col">
                            <h1 className="container-with-sidebar__title">Connected Users <small className="isnumber">({connectedUsers === null ? 0 : connectedUsers.length})</small></h1>
                        </div>
                        <div className="col text-right">
                            <Link to="/addConnection" className="btn-link-fancy small-text">
                                Add Connections&nbsp;
                <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{ color: 'rgb(55,136,176)' }}></FontAwesomeIcon>
                            </Link>
                        </div>
                    </div>
                </div>
                <section className="container-with-sidebar__content-block">
                    <div className="panel panel-data mt-1">
                        <div className="panel-body">
                            <div className="table-scroll">
                                <table className="providers-list">
                                    <tbody>
                                        <tr>
                                            <th>Provider</th>
                                            <th>Rol</th>
                                            <th>Status</th>
                                            <th>Quick Actions</th>
                                            <th></th>
                                        </tr>
                                        <tr className="clickable-row">
                                            <td>
                                                {currentUserRole === 'Authorized Representative' ?
                                                    <div className="user-info">
                                                        <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{`${primaryRepresentativeFirstName} ${primaryRepresentativeLastName}`}</strong><br />
                                                        {primaryRepresentativeEmail}
                                                    </div>
                                                    :
                                                    <div className="user-info">
                                                        <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{`${firstName} ${lastName}`}</strong><br />
                                                        {email}
                                                    </div>

                                                }
                                            </td>
                                            <td>Primary Representative</td>
                                            {/* <td>{currentUserRole}</td> */}
                                            <td>Active</td>
                                            <td>
                                                {/* Empty td Required for design */}
                                            </td>
                                            <td>
                                                {/* Empty td Required for design */}
                                            </td>

                                        </tr>

                                        {renderConnectedProviders()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div >
                </section>
            </div>
        </AuthContextProvider >
    );
}

export default Connections;