import React, { useEffect, useContext, useState } from 'react';
import Footer from '../Footer'
import UserDropDown from '../reusable/UserDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUsers, faUserLock, faBook, faHospital, faFileInvoiceDollar, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ProvidersDropDown from '../reusable/ProvidersDropDown';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import { ProvidersContext } from '../../contexts/ProvidersContext';

const DashboardSidebar = (props) => {

    useEffect(() => {
        console.log(props.location.pathname);
    }, [props.location.pathname]);

    const { isPMG, setIsPMG, connectedProviders } = useContext(LoggedUserContext);
    const { npi } = useContext(ProvidersContext);

    useEffect(() => {
        connectedProviders.map((cp) => {
            if (cp.npi === npi) {
                setIsPMG(cp.isPMG);
            }
        });
    }, [npi, connectedProviders]);

    // initialSidebarClass
    const [initialSidebarClass, setInitialSidebarClass] = useState('container-with-sidebar__sidebar--full');
    // initialContentClass
    const [initialContentClass, setInitialContentClass] = useState('container-with-sidebar__content js-toggle-content');
    // iconOnlyInitialSidebarClass
    const [iconOnlyInitialSidebarClass, setIconOnlyInitialSidebarClass] = useState('main-menu-vertical__item-list');

    const toggleVisibility = () => {
        if (initialSidebarClass === 'container-with-sidebar__sidebar--full') {
            setIconOnlyInitialSidebarClass('main-menu-vertical__item-list main-menu-vertical__item-list--icon-only');
            setInitialSidebarClass('container-with-sidebar__sidebar--narrow');
        } else {
            setIconOnlyInitialSidebarClass('main-menu-vertical__item-list');
            setInitialSidebarClass('container-with-sidebar__sidebar--full');
        }

        if (initialContentClass === 'container-with-sidebar__content js-toggle-content') {
            setInitialContentClass('container-with-sidebar__content container-with-sidebar__content--wide js-toggle-content')
        } else {
            setInitialContentClass('container-with-sidebar__content js-toggle-content')
        }


    }



    const menuItems = [
        {
            icon: faFileInvoiceDollar,
            name: 'Billing Dashboard',
            path: '/billing',
            disabled: false
        },
        {
            icon: faUsers,
            name: 'Providers Network & Population',
            path: '/providersNetworkAndPopulation',
            disabled: !isPMG
        },
        {
            icon: faHospital,
            name: 'Provider Information',
            path: '/personalInformation',
            disabled: true
        },
        {
            icon: faUserEdit,
            name: 'Revision Requests',
            path: '/revisionRequests',
            disabled: true
        },
        {
            icon: faUserLock,
            name: 'Connected Users',
            path: '/providerConnections',
            disabled: false
        },
        {
            icon: faBook,
            name: 'Glossary',
            path: '/glossary',
            disabled: false
        },
    ]

    const renderMenuItems = () => {
        return menuItems.map((menu) => {
            if (menu.disabled) {
                return <li key={menu.name} className="disabled" style={{ listStyle: 'none' }}> <span className="main-menu-vertical__ico"><FontAwesomeIcon icon={menu.icon} /></span><span className="main-menu-vertical__label">{menu.name}</span></li>
            }
            if (menu.path === '/providerConnections' && (props.location.pathname === '/addConnection' || props.location.pathname === '/addConnectionConfirmed')) {
                return <li key={menu.name} className="current" style={{ listStyle: 'none' }}><Link to={menu.path}><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={menu.icon} /></span> <span className="main-menu-vertical__label">{menu.name}</span></Link></li>
            }
            return <li key={menu.name} className={menu.path === props.location.pathname ? "current" : ''} style={{ listStyle: 'none' }}><Link to={menu.path}><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={menu.icon} /></span> <span className="main-menu-vertical__label">{menu.name}</span></Link></li>
        })
    }

    return (

        <div>
            {/* <!-- Header Slim --> */}
            <div className="container--fixed-top">
                <header className="main main--slim">
                    <div className="header-left header-col">
                        <button onClick={toggleVisibility} type="button" className="hamburguer-button  js-toggle-sidebar-btn "><FontAwesomeIcon icon={faBars} /></button><span className="ml-1 mr-1"><img className="logo-header" src="/images/logo-psc.svg" alt="PSC" /></span>
                    </div>
                    <div className="header-center header-col text-center">
                        <ProvidersDropDown />
                    </div>
                    <div className="header-right header-col">
                        <UserDropDown />
                    </div>
                </header>
            </div>
            {/* <!-- End Header Slim-- > */}

            <div className="container-with-sidebar has-container--fixed-top">
                {/* <!-- Sidebar for menu--> */}
                <div className={`container-with-sidebar__sidebar js-toggle-sidebar ${initialSidebarClass}`}>
                    {/* <!-- Menu Principal --> */}
                    <nav className="main-menu-vertical">
                        <ul className={`${iconOnlyInitialSidebarClass} js-toggle-label`}>
                            {renderMenuItems()}
                        </ul>
                    </nav>
                    {/* <!-- End Menu Principal --> */}
                </div>
                {/* <!-- End Sidebar for menu--> */}
                {/* <!-- Main Content--> */}
                <div className={initialContentClass}>
                    {props.children}
                    {/* <!-- Footer --> */}
                    <Footer />
                </div>
            </div>
        </div>

    );
}

export default DashboardSidebar;